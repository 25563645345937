import { faStore } from "@fortawesome/pro-regular-svg-icons/faStore";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";

export const marketplacePages = PageTree.build(BacksideRenderer, {
  "/app/marketplace": {
    views: {
      main: lazy(() => import("./page")),
    },
    icon: faStore,
    linkId: "marketplace",
  },
  "/app/marketplace/:extensionId": {
    views: {
      main: lazy(() => import("./[extensionId]/page")),
      pageTitle: lazy(() => import("./_components/ExtensionTitle")),
    },
    icon: faStore,
    linkId: "marketplace-extension",
  },
});
