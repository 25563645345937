import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import Select from "@mittwald/flow-components/dist/components/Select";
import React, { FC, useEffect } from "react";
import { ContainerList } from "../../../../model/container/ContainerList";
import {
  ContainerPort,
  portRegExp,
} from "../../../../model/container/ContainerPort";
import { AnyProject } from "../../../../model/project";

interface Props {
  project: AnyProject;
}

export const ContainerTargetFormFields: FC<Props> = (props) => {
  const form = useFormContext();
  const containers = ContainerList.useLoadAllByProjectId(props.project.id);
  const selectedContainerId = form.watch("container");
  const selectedPort = form.watch("containerPort");
  const selectedContainer = containers.items.find(
    (c) => c.id === selectedContainerId,
  );
  const portSelectOptions = selectedContainer?.getPortSelectOptions() ?? [];
  const containerOptions = containers.getSelectOptions();

  useEffect(() => {
    if (containerOptions.length === 1) {
      form.setValue("container", containerOptions[0]?.value);
    }
    if (portSelectOptions.length === 1) {
      form.setValue("containerPort", portSelectOptions[0]?.value);
    }
  }, []);

  useEffect(() => {
    if (portSelectOptions.length === 1) {
      form.setValue("containerPort", portSelectOptions[0]?.value);
    } else {
      if (!portSelectOptions.find((o) => o.value === selectedPort)) {
        form.setValue("containerPort", "");
      }
    }
  }, [selectedContainerId]);

  return (
    <ColumnLayout medium={[1, 1]}>
      <Select
        label="container"
        name="container"
        options={containerOptions}
        rules={{ required: true }}
      />
      <Select
        label="containerPort"
        name="containerPort"
        options={portSelectOptions}
        rules={{
          required: true,
          pattern: portRegExp,
          validate: { validPort: ContainerPort.validatePortNumbers },
        }}
      />
    </ColumnLayout>
  );
};
