import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import React, { FC } from "react";
import {
  ArticleFactory,
  ProSpaceArticleTag,
  ProSpaceResourceTag,
} from "../../../../../model/article";
import Bytes from "../../../../../model/misc/Bytes";
import ProSpaceProject from "../../../../../model/project/ProSpaceProject";
import OverviewStep from "../../../server/modals/OrderServerWizard/components/OverviewStep";
import ConfigurationStep from "./components/ConfigurationStep";

interface Props {
  project: ProSpaceProject;
}

export interface AdjustProSpaceFormFields {
  articleFilter: ProSpaceArticleTag;
  resourceFilter: ProSpaceResourceTag;
  dedicated: boolean;
  articleId: string;
  storageSizeInBytes: number;
}

export const AdjustProSpaceProject: FC<Props> = (props) => {
  const { project } = props;
  const currentArticle = project.useArticle();
  const projectContract = project.useContract();
  const currentStorage = project.useStorage();
  const proSpaceArticles = ArticleFactory.useLoadAllProSpaceArticles();

  const adjustProSpaceForm = useForm<AdjustProSpaceFormFields>({
    defaultValues: {
      dedicated:
        currentArticle.articleTag === ProSpaceArticleTag.proSpaceDedicated,
      articleFilter:
        currentArticle.articleTag === ProSpaceArticleTag.proSpaceLite
          ? ProSpaceArticleTag.proSpaceLite
          : ProSpaceArticleTag.proSpace,
      storageSizeInBytes: currentStorage.bytes,
      articleId: currentArticle.id,
      resourceFilter: currentArticle.resourceTag ?? ProSpaceResourceTag.cpu,
    },
    onSubmit: async (values) => {
      const selectedArticle =
        proSpaceArticles.find((a) => a.id === values.articleId) ??
        currentArticle;

      await project.adjust({
        storageSizeInBytes: values.storageSizeInBytes,
        contractId: projectContract.id,
        article: selectedArticle,
      });
    },
  });

  const [
    watchedArticleFilter,
    watchedDedicated,
    watchedStorageSizeInBytes,
    watchedResourceFilter,
    watchedArticleId,
  ] = adjustProSpaceForm.watch([
    "articleFilter",
    "dedicated",
    "storageSizeInBytes",
    "resourceFilter",
    "articleId",
  ]);

  const selectedArticle =
    proSpaceArticles.find((a) => a.id === watchedArticleId) ?? currentArticle;
  const watchedStorageSize = Bytes.of(watchedStorageSizeInBytes, "byte");
  const liteSelected = watchedArticleFilter === ProSpaceArticleTag.proSpaceLite;
  const dedicatedSelected =
    watchedArticleFilter === ProSpaceArticleTag.proSpace && watchedDedicated;

  const selectedProSpaceArticles = proSpaceArticles
    .filter((a) =>
      liteSelected
        ? a.articleTag === ProSpaceArticleTag.proSpaceLite
        : dedicatedSelected
          ? a.articleTag === ProSpaceArticleTag.proSpaceDedicated
          : a.articleTag === ProSpaceArticleTag.proSpace,
    )
    .filter((a) => liteSelected || watchedResourceFilter === a.resourceTag);

  if (selectedProSpaceArticles.length > 0) {
    const articleWithSameSpecs = selectedProSpaceArticles.find(
      (a) => a.machineType.cpu === selectedArticle.machineType.cpu,
    );
    const firstSelectedArticleId = selectedProSpaceArticles[0]!.id;

    if (!selectedProSpaceArticles.find((a) => a.id === watchedArticleId)) {
      adjustProSpaceForm.setValue(
        "articleId",
        articleWithSameSpecs?.id ?? firstSelectedArticleId,
      );
    }
  }

  const configurationStep = (
    <ConfigurationStep
      contractItem={projectContract.baseItem}
      currentArticle={currentArticle}
      currentStorage={currentStorage}
      liteSelected={liteSelected}
      projectId={project.id}
      selectedArticle={selectedArticle}
      selectedProSpaceArticles={selectedProSpaceArticles}
      watchedStorageSize={watchedStorageSize}
    />
  );

  const overviewStep = (
    <OverviewStep
      contractItem={projectContract.baseItem}
      customerId={project.customerId}
      selectedArticle={selectedArticle}
      storageSize={watchedStorageSize}
    />
  );

  return (
    <WizardModal
      _size="l"
      form={adjustProSpaceForm}
      steps={["configuration", "overview"]}
    >
      {configurationStep}
      {overviewStep}
    </WizardModal>
  );
};

export default AdjustProSpaceProject;
