import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { useCurrentLanguage } from "@mittwald/flow-lib/dist/hooks/useCurrentLanguage";
import appendUtcSuffixToTimeString from "@mittwald/flow-lib/dist/lib/appendUtcSuffixToTimeString";
import cronstrue from "cronstrue/i18n";
import { ReactNode } from "react";
import { isDigitString } from "../../../lib/isDigitString";
import { randomNumberFromRange } from "../../../lib/randomNumberFromRange";

export type ScheduleTimeInterval =
  | "1m"
  | "5m"
  | "30m"
  | "1h"
  | "1d"
  | "7d"
  | "14d"
  | "30d"
  | "custom";

export class ScheduleUI {
  public static getTimeOptions(): SelectOptions {
    return Array(24)
      .fill(0)
      .map((_, index) => ({
        value: index.toString(),
        label: { id: "scheduleTime", values: { time: index } },
      }));
  }

  public static getIntervalOptions(
    intervals: ScheduleTimeInterval[],
  ): SelectOptions {
    return intervals.map((interval) => ({
      value: interval,
      label: joinTranslationKeys("scheduleInterval", interval),
    }));
  }

  public static updateScheduleWithInterval(
    schedule: string,
    interval: ScheduleTimeInterval,
  ): string | undefined {
    const cronParts = schedule.split(" ");
    const minute =
      cronParts[0] && isDigitString(cronParts[0])
        ? cronParts[0]
        : randomNumberFromRange(0, 59);
    const hour =
      cronParts[1] && isDigitString(cronParts[1])
        ? cronParts[1]
        : randomNumberFromRange(0, 23);
    const day =
      cronParts[2] && isDigitString(cronParts[2])
        ? cronParts[2]
        : randomNumberFromRange(1, 31);
    const weekday =
      cronParts[4] && isDigitString(cronParts[4])
        ? cronParts[4]
        : randomNumberFromRange(0, 6);
    switch (interval) {
      case "1m":
        return "* * * * *";
      case "5m":
        return "*/5 * * * *";
      case "30m":
        return "*/30 * * * *";
      case "1h":
        return `${minute} * * * *`;
      case "1d":
        return `${minute} ${hour} * * *`;
      case "7d":
        return `${minute} ${hour} * * ${weekday}`;
      case "14d":
        return `${minute} ${hour} 1,15 * *`;
      case "30d":
        return `${minute} ${hour} ${day} * *`;
      default:
        return;
    }
  }

  public static updateScheduleWithTime(
    schedule: string,
    time: string,
  ): string | undefined {
    if (time === "custom") {
      return;
    }
    const cronParts = schedule.split(" ");
    cronParts[1] = time;
    cronParts[0] = "0";
    return cronParts.join(" ");
  }

  public static getIntervalFromSchedule(
    schedule: string,
  ): Partial<ScheduleTimeInterval> {
    const cronParts = schedule.split(" ");
    if (
      cronParts[0] === "*" &&
      cronParts[1] === "*" &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "1m";
    } else if (
      cronParts[0] === "*/5" &&
      cronParts[1] === "*" &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "5m";
    } else if (
      cronParts[0] === "*/30" &&
      cronParts[1] === "*" &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "30m";
    } else if (
      isDigitString(cronParts[0]) &&
      cronParts[1] === "*" &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "1h";
    } else if (
      isDigitString(cronParts[0]) &&
      isDigitString(cronParts[1]) &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "1d";
    } else if (
      isDigitString(cronParts[0]) &&
      isDigitString(cronParts[1]) &&
      cronParts[2] === "*" &&
      cronParts[3] === "*" &&
      isDigitString(cronParts[4])
    ) {
      return "7d";
    } else if (
      isDigitString(cronParts[0]) &&
      isDigitString(cronParts[1]) &&
      cronParts[2] === "1,15" &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "14d";
    } else if (
      isDigitString(cronParts[0]) &&
      isDigitString(cronParts[1]) &&
      isDigitString(cronParts[2]) &&
      cronParts[3] === "*" &&
      cronParts[4] === "*"
    ) {
      return "30d";
    } else return "custom";
  }

  public static isSmallIntervall(schedule: string): boolean {
    const cronParts = schedule.split(" ");

    if (
      cronParts[1] !== "*" ||
      cronParts[2] !== "*" ||
      cronParts[3] !== "*" ||
      cronParts[4] !== "*" ||
      !cronParts[0]
    ) {
      return false;
    }

    if (cronParts[0] === "*") {
      return true;
    }

    if (!cronParts[0]?.includes("/")) {
      return false;
    }

    const partAfterSlash = cronParts[0].split("/")[1];

    if (partAfterSlash && parseInt(partAfterSlash) <= 5) {
      return true;
    }

    return false;
  }

  public static getTimeFromSchedule(schedule: string): string {
    const cronParts = schedule.split(" ");
    const cronMinute = cronParts[0];
    const cronHour = cronParts[1];

    if (!cronHour || cronMinute !== "0") {
      return "custom";
    }
    const hour = parseInt(cronHour);
    if (Number.isInteger(hour) && hour >= 0 && hour <= 23) {
      return hour.toString();
    } else return "custom";
  }

  public static useScheduleText(schedule: string): string | ReactNode {
    const { language } = useCurrentLanguage();
    const translate = useTranslation();
    // if any times are found add a (UTC) suffix
    const cronString = appendUtcSuffixToTimeString(
      cronstrue.toString(schedule, {
        locale: language,
        use24HourTimeFormat: true,
      }),
    );

    let returnString: ReactNode | string = cronString;

    const atExpressions = [/\bum\b/i, /\bat\b/i];
    atExpressions.forEach((at) => {
      if (at.test(cronString)) {
        returnString = translate("around", {
          values: { timeVal: cronString.replace(at, "").trim() },
        });
      }
    });

    return returnString;
  }

  public static getTimeTextFromSchedule(schedule: string): string {
    const cronParts = schedule.split(" ");
    const cronHours = cronParts[1];
    const cronMinutes = cronParts[0];

    if (!cronHours || !cronMinutes) {
      throw new Error("invalid cron syntax");
    }

    return `${cronHours.length === 1 ? "0" + cronHours : cronHours}:${
      cronMinutes.length === 1 ? "0" + cronMinutes : cronMinutes
    }`;
  }
}

export default ScheduleUI;
