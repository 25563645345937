import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { DnsZoneUI, Ttl } from "../ui/domain/DnsZoneUI";
import { CaaRecordApiData, CaaRecordSet } from "./CaaRecordSet";
import { DnsZoneApiData } from "./DnsZone";

export interface CaaRecordListItem {
  flags: "undefined" | number;
  tag: "issue" | "issuewild" | "iodef";
  value: string;
}

export class CaaRecordList extends ListModel<CaaRecordSet> {
  public readonly recordCount: number;
  public readonly zoneId: string;

  public readonly ttl: Ttl;
  public constructor(items: CaaRecordSet[], ttl: Ttl = "auto", zoneId: string) {
    super(items);
    this.ttl = ttl;
    this.recordCount = items.length;
    this.zoneId = zoneId;
  }

  public static fromDnsZoneApiData = (data: DnsZoneApiData): CaaRecordList => {
    const records =
      "records" in data.recordSet.caa
        ? data.recordSet.caa.records.map((i) => CaaRecordSet.fromApiData(i))
        : [];

    const ttl = DnsZoneUI.ttlFromRecord(data);

    return new CaaRecordList(records, ttl, data.id);
  };

  public useDataItems(): CaaRecordListItem[] {
    const items = this.useItems();
    const dataItems: CaaRecordListItem[] = items.map((i) => ({
      ...i,
      flags: i.flags === 0 || i.flags === undefined ? "undefined" : i.flags,
    }));
    return useMemo(() => dataItems, [items]);
  }

  public async setRecords(
    records: CaaRecordListItem[],
    ttl: Ttl,
  ): Promise<void> {
    const items: CaaRecordApiData[] = records.map((i) => ({
      ...i,
      flags: i.flags === "undefined" ? 0 : i.flags,
    }));

    await mittwaldApi.dnsUpdateRecordSet.request({
      path: {
        dnsZoneId: this.zoneId,
        recordSet: "caa",
      },
      requestBody: {
        records: items,
        settings: {
          ...DnsZoneUI.ttlToRecordSettings(ttl),
        },
      },
    });
  }
}
