import { useFeatureEnabled } from "@mittwald/flow-lib/dist/hooks/useFeatureEnabled";
import React, { FC } from "react";
import { Project } from "../../../../model/project";
import { TopNavigationExtensions } from "./TopNavigationExtensions";

export const TopNavigationAppExtensionsForProject: FC = () => {
  const project = Project.useLoadByPathParam();

  const featureEnabled = useFeatureEnabled("showRemoteExtensionMenu");
  if (!featureEnabled) {
    return null;
  }

  return (
    <TopNavigationExtensions
      context="project"
      fragmentPath="/projects/project/apps/detail/menu-top/item"
      identifier={project.id}
      linkId="projectAppExtensionFragmentView"
    />
  );
};
