import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import dnsZoneFile from "../../lib/dnsZoneFile";
import { forceDownload } from "../../lib/download";
import Domain, { defaultNameserver } from "../domain/Domain";
import Ingress from "../domain/Ingress";
import DnsZoneUI, { Ttl } from "../ui/domain/DnsZoneUI";
import { ARecord, ARecordApiData } from "./ARecord";
import { CaaRecordList } from "./CaaRecordList";
import { CnameRecordApiData } from "./CnameRecord";
import { CnameRecordsList } from "./CnameRecordsList";
import { MxRecord, MxRecordRecordApiData } from "./MxRecord";
import { SrvRecordSetApiData } from "./SrvRecordSet";
import { SrvRecordsList } from "./SrvRecordsList";
import { TxtRecordSetApiData } from "./TxtRecordSet";
import { TxtRecordsList } from "./TxtRecordsList";

export type DnsZoneApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_Zone;

export type RecordSettings =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordSettings;

export type RecordUnset =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordUnset;

export type DnsRecordApiData =
  | ARecordApiData
  | CnameRecordApiData
  | TxtRecordSetApiData
  | MxRecordRecordApiData
  | SrvRecordSetApiData;

export const defaultMxZones = [
  { host: "mx1.agenturserver.de", priority: 10 },
  { host: "mx2.agenturserver.de", priority: 10 },
  { host: "mx3.agenturserver.de", priority: 20 },
  { host: "mx4.agenturserver.de", priority: 50 },
];

export class DnsZone {
  public readonly data: DnsZoneApiData;
  public readonly id: string;
  public readonly ingress: Ingress;
  public readonly aRecord: ARecord;
  public readonly cnameRecordsList: CnameRecordsList;
  public readonly mxRecord: MxRecord;
  public readonly txtRecordsList: TxtRecordsList;
  public readonly srvRecordsList: SrvRecordsList;
  public readonly caaRecordsList: CaaRecordList;
  public readonly ttl: Ttl;

  private constructor(data: DnsZoneApiData, ingress: Ingress) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.ingress = ingress;
    this.aRecord = ARecord.fromApiData(
      data.recordSet.combinedARecords,
      this.id,
    );
    this.mxRecord = MxRecord.fromApiData(this.data.recordSet.mx, this.id);

    this.cnameRecordsList = CnameRecordsList.useLoadAllByIngress(ingress);

    const zonesFromIngress = TxtRecordsList.useLoadAllByIngress(ingress);
    const zonesFromApiData = TxtRecordsList.fromTxtRecordApiData(data, ingress);
    this.txtRecordsList =
      zonesFromIngress.recordCount === 0 ? zonesFromApiData : zonesFromIngress;

    const srvFromIngress = SrvRecordsList.useLoadAllByIngress(ingress);
    const srvFromApiData = SrvRecordsList.fromSrvRecordApiData(data, ingress);
    this.srvRecordsList =
      srvFromIngress.recordCount === 0 ? srvFromApiData : srvFromIngress;

    this.caaRecordsList = CaaRecordList.fromDnsZoneApiData(data);
    this.ttl =
      [
        this.mxRecord,
        this.aRecord,
        this.cnameRecordsList,
        this.srvRecordsList,
        this.txtRecordsList,
        this.caaRecordsList,
      ].find((d) => d.ttl)?.ttl ?? "auto";
  }

  public static fromApiData = (
    data: DnsZoneApiData,
    ingress: Ingress,
  ): DnsZone => {
    return new DnsZone(data, ingress);
  };

  public static fromExternalDomain = (ingress: Ingress): DnsZone => {
    const txtRecords = ingress.isSubdomain
      ? []
      : [
          DnsZoneUI.defaultTxtRecord,
          {
            host: `agenturserver._domainkey.${ingress.name.domain}`,
            value: DnsZoneUI.dkim,
          },
        ];

    const cnameRecords = ingress.isSubdomain
      ? {}
      : {
          fqdn: "autoconfig.agenturserver.de.",
          domain: `autoconfig.${ingress.name.domain}.`,
        };

    const mxRecords = ingress.isSubdomain
      ? []
      : defaultMxZones.map((zone) => ({
          fqdn: zone.host,
          priority: zone.priority,
        }));

    return new DnsZone(
      {
        domain: ingress.name.domain,
        recordSet: {
          txt: {
            entries: txtRecords,
            settings: {},
          },
          srv: { records: [], settings: {} },
          cname: {
            fqdn: cnameRecords,
            settings: {},
          },
          combinedARecords: {
            a: ingress.ipv4Adresses,
            aaaa: [],
            settings: {},
          },
          mx: {
            records: mxRecords,
            settings: {},
          },
          caa: { records: [], settings: {} },
        },
        id: ingress.id,
      },
      Ingress.fromApiData(
        ingress.data,
        Domain.fromApiData({
          domain: ingress.name.domain,
          projectId: ingress.projectId,
          domainId: "fake-domain-id-for-external-domain",
          nameservers: defaultNameserver,
          usesDefaultNameserver: false,
          connected: true,
          deleted: true,
          transferInAuthCode: undefined,
          handles: {
            ownerC: {
              current: {},
            },
          },
        }),
      ),
    );
  };

  public static useIsZonePresentForIngress(ingress: Ingress): boolean {
    const data = mittwaldApi.dnsListDnsZones
      .getResource({
        path: { projectId: ingress.data.projectId },
      })
      .useWatchData({
        retry: false,
        optional: true,
      });

    return !!(data && data.find((d) => d.domain === ingress.hostname));
  }

  public static async getByIngress(ingress: Ingress): Promise<DnsZoneApiData> {
    const response = await mittwaldApi.dnsListDnsZones.request({
      path: { projectId: ingress.data.projectId },
    });

    assertStatus(response, 200);
    const dnsZoneForIngress = response.content.find(
      (d) => d.domain === ingress.hostname,
    );

    if (!dnsZoneForIngress) {
      throw new Error("DNS Zone not found");
    }

    return dnsZoneForIngress;
  }

  public static useLoadByPathParam(): DnsZone {
    const { ingressId } = usePathParams("ingressId");
    const ingress = Ingress.useLoadById(ingressId);
    return DnsZone.useLoadByIngress(ingress);
  }

  public static useLoadByIngress(ingress: Ingress): DnsZone {
    const apiRequest = mittwaldApi.dnsListDnsZones.getResource({
      path: { projectId: ingress.data.projectId },
    });
    const data = apiRequest.useWatchData();
    const dnsZoneForIngress = data.find((d) => d.domain === ingress.hostname);

    if (!dnsZoneForIngress) {
      return this.fromExternalDomain(ingress);
    }

    return this.fromApiData(dnsZoneForIngress, ingress);
  }

  public downloadZoneFile(): void {
    const ttl = DnsZoneUI.ttlToSeconds(this.ttl);
    const domain = `${this.ingress.name.domain}.`;

    const firstNs = this.ingress.domain?.nameservers[0];
    const soaMname = firstNs
      ? `${firstNs}.`
      : "a.misconfigured.dns.server.invalid.";

    const aRecords = this.aRecord.isManaged
      ? this.ingress.ipv4Adresses.map((address) => ({
          name: domain,
          ip: address,
          ttl,
        }))
      : this.aRecord.records.map((aRecord) => ({
          name: domain,
          ip: aRecord,
          ttl,
        }));

    const mxRecords = this.mxRecord.isManaged
      ? defaultMxZones.map((zone) => ({
          name: domain,
          host: `${zone.host}.`,
          ip: zone.host,
          preference: zone.priority,
        }))
      : this.mxRecord.records.map((mxRecord) => ({
          name: domain,
          host: `${mxRecord.fqdn}.`,
          ip: mxRecord.fqdn,
          preference: mxRecord.priority,
          ttl,
        }));

    const zoneFileContents = dnsZoneFile.generate({
      $origin: domain,
      $ttl: DnsZoneUI.ttlToSeconds(ttl),
      soa: {
        name: domain,
        expire: 604800,
        minimum: 3600,
        mname: soaMname,
        refresh: 10800,
        retry: 3600,
        rname: `hostmaster.${domain}`,
        serial: Number.parseInt(DateTime.now().toFormat("yyyyLLddHH")),
        ttl,
      },
      a: aRecords,
      cname: this.cnameRecordsList.getItems().map((cname) => ({
        name: cname.hostname,
        alias: cname.fqdn,
        ttl,
      })),
      srv: this.srvRecordsList.getItems().map((s) => ({
        ...s,
        priority: s.priority ?? 0,
        weight: s.weight ?? 0,
        name: `${s.service}.${s.protocol}.${domain}`,
        target: s.fqdn,
      })),
      mx: mxRecords,
      txt: this.txtRecordsList
        .getItems()
        .map((txt) => ({ ...txt, name: `${txt.hostname}.` })),
      ns: this.ingress.domain?.nameservers.map((nameserver) => ({
        name: domain,
        host: `${nameserver}.`,
        ttl,
      })),
    });

    forceDownload(zoneFileContents, `${this.ingress.name.domain}.zones.txt`);
  }
}

export default DnsZone;
