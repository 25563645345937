import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import { Section as NewSection } from "@mittwald/flow-react-components";
import React, { FC } from "react";
import DisableForm from "../../../../../../components/DisableForm";
import { SpaceServerArticle } from "../../../../../../model/article";
import Contract from "../../../../../../model/contract/Contract";
import Bytes from "../../../../../../model/misc/Bytes";
import Server from "../../../../../../model/server/Server";
import MachineTypeSection from "../../OrderServerWizard/components/MachineTypeSection";
import StorageSection from "../../OrderServerWizard/components/StorageSection";
import TariffChangePricePreviewBox from "./TariffChangePricePreviewBox";

interface Props {
  selectedArticle: SpaceServerArticle;
  currentArticle: SpaceServerArticle;
  server: Server;
  selectedStorageSize: Bytes;
  serverContract: Contract;
}

export const ServerStep: FC<Props> = (props) => {
  const {
    selectedArticle,
    currentArticle,
    server,
    selectedStorageSize,
    serverContract,
  } = props;

  const configHasChanged =
    !selectedArticle.isEqual(currentArticle) ||
    !server.storage.bytes.isEqualTo(selectedStorageSize);

  return (
    <WizardStep
      id="server"
      indicatorText="serverStep"
      infoBoxContent={
        <TariffChangePricePreviewBox
          article={selectedArticle}
          contractItem={serverContract.baseItem}
          showNewPrice={configHasChanged}
          storageSize={selectedStorageSize}
        />
      }
    >
      <Suspense loadingView={<LoadingView />}>
        <NewSection>
          <MachineTypeSection server={server} />
        </NewSection>
        <NewSection>
          <StorageSection article={selectedArticle} />
        </NewSection>
        {!configHasChanged && <DisableForm />}
      </Suspense>
    </WizardStep>
  );
};

export default ServerStep;
