import { NavigationItem } from "@mittwald/flow-components/dist/components/Navigation/components/NavigationItem";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import { useIsActiveRoute } from "@mittwald/flow-lib/dist/hooks/useIsActiveRoute";
import { usePageTreeContext } from "@mittwald/flow-lib/dist/hooks/usePageTreeContext";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import { generatePath, useResolvedPath } from "react-router-dom";
import Extension from "../../../../../model/marketplace/Extension";
import ExtensionInstance from "../../../../../model/marketplace/ExtensionInstance";

interface NavigationExtensionItemProps {
  extensionInstance: ExtensionInstance;
  fragmentPath: string;
  linkId: string;
}

const ExtensionItem: FC<{
  extension: Extension;
  extensionInstance: ExtensionInstance;
  title: I18nDefinition;
  fragment: string;
  linkId: string;
}> = (props) => {
  const { extension, extensionInstance, fragment, title, linkId } = props;
  const currentParams = usePathParams();
  const context = usePageTreeContext();
  const detailExtensionViewPath = context.pageTree.getPath(linkId);
  const { icon = iconExtension } =
    extension.frontendFragments[fragment]?.additionalProperties ?? {};

  const pathParams = {
    ...currentParams,
    extensionInstanceId: extensionInstance.id,
    extensionFragment: fragment,
  };

  const resolvedPath = useResolvedPath(
    generatePath(detailExtensionViewPath, pathParams),
  );

  const isActive = useIsActiveRoute(resolvedPath.pathname);

  return (
    <NavigationItem
      _internal={{
        active: isActive,
      }}
      action={{
        link: {
          id: linkId,
          options: {
            pathParams,
            queryParams: {
              fragment: window.btoa(fragment),
            },
          },
        },
      }}
      icon={icon}
      title={title}
    />
  );
};

export const NavigationExtensionItem: FC<NavigationExtensionItemProps> = (
  props,
) => {
  const { extensionInstance, fragmentPath, linkId } = props;
  const extension = Extension.useLoadById(extensionInstance.extensionId);

  if (!extension.hasFrontendFragment(fragmentPath)) {
    return null;
  }

  return (
    <ExtensionItem
      extension={extension}
      extensionInstance={extensionInstance}
      fragment={fragmentPath}
      linkId={linkId}
      title={{ text: extension.data.name }}
    />
  );
};
