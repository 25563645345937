import { iconApp } from "@mittwald/flow-icons/dist/app";
import { combine } from "@mittwald/flow-lib/dist/access/combine";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import AppInstallation from "../../../model/app/AppInstallation";
import { AppInstallationList } from "../../../model/app/AppInstallationList";
import Model from "../../../model/Model";
import { TopNavigationAppExtensionsForProject } from "../extensions/navigation/TopNavigationAppExtensionsForProject";

export const appPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/apps": {
    views: {
      main: lazy(() => import("./pages/ProjectOverview")),
    },
    props: {
      navigationSection: "component",
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(AppInstallation, "create"),
          },
          icon: iconApp,
          title: "createAppInstallation",
        },
      ],
    },
    icon: iconApp,
    linkId: "apps",
    accessCheck: () =>
      ModelActionBuilder.buildBase(AppInstallationList, "useLoadAllByProjectId")
        .hasAccess,
  },

  "/app/projects/:projectId/apps/:appInstallationId": {
    views: {
      topMenuNavigationRight: TopNavigationAppExtensionsForProject,
      pageTitle: lazy(() => import("./components/InstallationTitle")),
    },
    props: {
      tabNav: true,
    },
    linkId: "appOverview",
  },

  "/app/projects/:projectId/apps/:appInstallationId/general": {
    views: {
      main: lazy(
        () => import("./pages/appInstallationDetails/appInstallationDetails"),
      ),
    },
    linkId: "appDetails",
    hooks: {
      useCheckState: () =>
        AppInstallation.useLoadByPathParam().useUpdateAvailable()
          ? "info"
          : null,
    },
  },
  "/app/projects/:projectId/apps/:appInstallationId/linked": {
    views: {
      main: lazy(
        () => import("./pages/appInstallationLinks/AppInstallationLinks"),
      ),
    },
    linkId: "appInstallationLinks",
  },

  "/app/projects/:projectId/apps/:appInstallationId/software": {
    views: {
      main: lazy(
        () =>
          import(
            "./pages/installedSystemSoftwareOverview/installedSystemSoftwareOverview"
          ),
      ),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                AppInstallation.useLoadByPathParam(),
                "installSystemSoftware",
              ),
          },
          icon: iconApp,
          title: "installSystemSoftware",
        },
      ],
    },
    linkId: "softwareOverview",
    hooks: {
      useCheckState: () => {
        const php = AppInstallation.useLoadByPathParam().useInstalledPhp();
        const phpVersion = php?.useVersion() ?? Model.useDryApi();
        const hasImminentExpiryDate = !!phpVersion?.checkImminentExpiryDate();
        const hasImminentFee = !!phpVersion?.checkImminentFee();

        return hasImminentExpiryDate
          ? "warning"
          : hasImminentFee
            ? "info"
            : null;
      },
    },
  },

  "/app/projects/:projectId/apps/:appInstallationId/config": {
    views: {
      main: lazy(
        () => import("./pages/appInstallationSettings/appInstallationSettings"),
      ),
    },
    linkId: "softwareConfiguration",

    accessCheck: combine.or(
      () =>
        ModelActionBuilder.buildBase(
          AppInstallation.useLoadByPathParam(),
          "updateUserInputs",
        ).hasAccess,
      () =>
        AppInstallation.useLoadByPathParam().useVersion().data
          .docRootUserEditable,
    ),
  },

  "/app/projects/:projectId/apps/:appInstallationId/:extensionInstanceId": {
    linkId: "projectAppExtensionFragmentView",
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionView/ExtensionInstanceFragmentForProject"
          ),
      ),
    },
  },
});
