import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";
import ContractItem from "../../../../../../model/contract/ContractItem";

interface Props {
  terminationTargetDate?: string;
  contractItem: ContractItem;
}

export const ConfirmationStepContent: FC<Props> = (props) => {
  const { terminationTargetDate, contractItem } = props;

  const referenceData = contractItem.useReference();

  return (
    <Section.Item headline="terminationOverview">
      <Text
        i18n={{
          id: "terminationDescription",
        }}
      />
      <ColumnLayout medium={[1, 1]}>
        <StaticInformation
          label="contractItemName"
          text={{
            text: referenceData.description,
          }}
        />
        {!!contractItem.baseArticle && (
          <StaticInformation
            label="articleName"
            text={{ text: contractItem.baseArticle.name }}
          />
        )}
        {referenceData.shortId && (
          <StaticInformation
            label="shortId"
            text={{ text: referenceData.shortId }}
          />
        )}
        <StaticInformation
          label="terminationDate"
          text={<DateTime format="date" value={terminationTargetDate} />}
        />
      </ColumnLayout>
    </Section.Item>
  );
};

export default ConfirmationStepContent;
