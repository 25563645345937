import Form, { useForm } from "@mittwald/flow-components/dist/components/Form";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import React, { FC } from "react";
import Customer from "../../../../../model/customer/Customer";
import ProSpaceArticleUI from "../../../../../model/ui/article/ProSpaceArticleUI";
import CreateProject from "./CreateProject";
import { CreateServerProject } from "./CreateServerProject";

interface ServerProjectFormFields {
  proSpaceOrSpaceServer: "proSpace" | "spaceServer";
}

interface Props {
  customer?: Customer;
}

export const SelectProjectTypeModal: FC<Props> = (props) => {
  const { customer } = props;

  const modalVisibility = useVisibilityController();

  const form = useForm<ServerProjectFormFields>({
    defaultValues: {
      proSpaceOrSpaceServer: "proSpace",
    },
    onSubmit: (values) => {
      if (values.proSpaceOrSpaceServer === "proSpace") {
        showModal("createProject", <CreateProject customer={customer} />);
      } else {
        showModal("createProject", <CreateServerProject customer={customer} />);
      }
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createProject"
      headlineIcon={iconProject}
      primary={{
        action: [{ form }, modalVisibility.hide],
      }}
      visibility={modalVisibility}
    >
      <Form controller={form}>
        <Section.Item>
          <SelectBox
            name="proSpaceOrSpaceServer"
            options={ProSpaceArticleUI.useArticleTypeOptions(
              props.customer?.id,
            )}
            rules={{ required: true }}
          />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};
