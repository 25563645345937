import {
  AccentBox,
  Heading,
  Icon,
  Link,
  Section,
  Text,
} from "@mittwald/flow-react-components";
import { IconLeaf } from "@tabler/icons-react";
import React, { FC } from "react";

export const ClimateBox: FC = () => {
  return (
    <AccentBox>
      <Icon>
        <IconLeaf />
      </Icon>
      <Section>
        <Heading level={3}>Dein Cronjob läuft sehr häufig</Heading>
        <Text>
          Bitte überlege, ob der Cronjob weniger oft laufen kann. Falls du einen
          Worker-Prozess benötigst, kannst du statt eines Cronjobs eine{" "}
          <Link
            href="https://developer.mittwald.de/de/docs/v2/platform/workloads/php-worker/"
            target="_blank"
          >
            &quot;PHP-Worker&quot;-App
          </Link>{" "}
          nutzen. Das spart Energie und schützt das Klima.
        </Text>
        <Link
          href="https://www.mittwald.de/blog/nachhaltigkeit/warum-es-der-umwelt-hilft-wenn-du-cronjobs-clever-timest-und-reduzierst"
          target="_blank"
        >
          Blogartikel mit Hintergrund-Informationen
        </Link>
      </Section>
    </AccentBox>
  );
};
