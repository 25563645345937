import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { MarketplaceContext } from "./Extension";
import ExtensionInstance from "./ExtensionInstance";

class ExtensionInstanceList extends ListModel<ExtensionInstance> {
  public static useLoadAll(
    context: MarketplaceContext,
    contextId: string,
  ): ExtensionInstanceList {
    const instances = mittwaldApi.extensionListExtensionInstances
      .getResource({ query: { context, contextId } })
      .useWatchData()
      .map((instance) => new ExtensionInstance(instance));

    return new ExtensionInstanceList(instances);
  }

  public static async loadAll(
    context: MarketplaceContext,
    contextId: string,
  ): Promise<ExtensionInstanceList> {
    const response = await mittwaldApi.extensionListExtensionInstances.request({
      query: { context, contextId },
    });

    assertStatus(response, 200);

    const instances = response.content.map(
      (instance) => new ExtensionInstance(instance),
    );

    return new ExtensionInstanceList(instances);
  }
}

export default ExtensionInstanceList;
