import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AggregateReferenceApiSchema } from "../../../../../model/support/Conversation";
import { getDefaultRelatedToChild } from "../../../../../pages/app/support/misc/getDefaultRelatedToChild";
import CreateConversation from "../../../../../pages/app/support/modals/CreateConversation/CreateConversation";
import { getConversationDetailsFromPathname } from "../utils/helpers";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { VisibilityControllable } from "@mittwald/flow-components/dist/hooks/useVisibilityController";

interface CreateSupportRequestModalProps extends VisibilityControllable {}

interface AggregateReference extends Omit<AggregateReferenceApiSchema, "id"> {
  id?: string;
}

export const CreateSupportRequestModal: React.FC<
  CreateSupportRequestModalProps
> = (props) => {
  const { visibility } = props;
  const { pathname } = useLocation();

  const defaultRelatedToChild = getDefaultRelatedToChild();

  const [relatedTo, setRelatedTo] = useState<AggregateReference>({
    domain: "unknown",
    aggregate: "unknown",
  });
  const [relatedToChild, setRelatedToChild] = useState<AggregateReference>({
    domain: defaultRelatedToChild.domain,
    aggregate: defaultRelatedToChild.aggregate,
  });
  const autoSaveKey = `createConversation-${pathname.split("/")[2]}-${
    pathname.split("/")[3]
  }`;
  const [categoryName, setCategoryName] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const result = getConversationDetailsFromPathname(pathname);
    setRelatedTo(result.relatedTo);
    if (result.relatedToChild) {
      setRelatedToChild(result.relatedToChild);
    }
    setCategoryName(result.categoryName);
  }, [pathname]);

  return (
    <TranslationProvider name="createConversation" type="modal">
      <CreateConversation
        autoSaveKey={autoSaveKey}
        categoryName={categoryName}
        redirectAfterCreate={false}
        relatedTo={relatedTo}
        relatedToChild={relatedToChild}
        visibilityController={visibility}
      />
    </TranslationProvider>
  );
};

export default CreateSupportRequestModal;
