import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import { Markdown } from "@mittwald/flow-react-components";
import React, { FC } from "react";
import ChangelogEntry from "../../model/changelog/ChangelogEntry";

interface Props {
  changelogs: ChangelogEntry[];
}

export const ChangelogEntriesList: FC<Props> = (props) => (
  <ColumnLayoutContainer>
    {props.changelogs.map((entry) => (
      <Box key={entry.id}>
        {entry.createdAt && (
          <H3>{new Date(entry.createdAt).toLocaleDateString()}</H3>
        )}
        <Markdown>{entry.content}</Markdown>
      </Box>
    ))}
  </ColumnLayoutContainer>
);

export default ChangelogEntriesList;
