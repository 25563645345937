import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { PageSkeleton } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/PageSkeleton";
import React, { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import ExtensionInstance from "../../../model/marketplace/ExtensionInstance";

export const extensionPages = PageTree.build(BacksideRenderer, {
  "/app/projects/:projectId/extensions-view": {
    props: {
      hideInMenu: true,
    },
    views: {
      main: () => <PageSkeleton redirectTo="projectExtensions" />,
    },
  },
  "/app/projects/:projectId/extensions-view/:extensionInstanceId": {
    views: {
      pageTitle: lazy(() => import("./components/ExtensionInstanceTitle")),
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionView/ExtensionInstanceFragmentForProjectWithLayout"
          ),
      ),
    },
    icon: iconExtension,
    linkId: "projectExtensionFragmentView",
  },
  "/app/projects/:projectId/extensions": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceOverview/ExtensionInstanceOverviewForProject"
          ),
      ),
    },
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                ExtensionInstance,
                "addToProjectContext",
              ),
          },
          icon: iconExtension,
          title: "addToContext",
        },
      ],
      beta: true,
    },
    icon: iconExtension,
    linkId: "projectExtensions",
  },
  "/app/projects/:projectId/extensions/:extensionInstanceId": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceDetails/ExtensionInstanceDetails"
          ),
      ),
      pageTitle: lazy(
        () => import("../extensions/components/ExtensionInstanceTitle"),
      ),
    },
    linkId: "projectExtensionInstance",
  },

  "/app/organizations/:customerId/extensions-view": {
    props: {
      hideInMenu: true,
    },
    views: {
      main: () => <PageSkeleton redirectTo="customerExtensions" />,
    },
  },
  "/app/organizations/:customerId/extensions-view/:extensionInstanceId": {
    views: {
      pageTitle: lazy(() => import("./components/ExtensionInstanceTitle")),
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionView/ExtensionInstanceFragmentForCustomer"
          ),
      ),
    },
    icon: iconExtension,
    linkId: "customerExtensionFragmentView",
  },
  "/app/organizations/:customerId/extensions": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceOverview/ExtensionInstanceOverviewForCustomer"
          ),
      ),
    },
    props: {
      navigationSection: "settings",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                ExtensionInstance,
                "addToCustomerContext",
              ),
          },
          icon: iconExtension,
          title: "addToContext",
        },
      ],
      beta: true,
    },
    icon: iconExtension,
    linkId: "customerExtensions",
  },

  "/app/organizations/:customerId/extensions/:extensionInstanceId": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceDetails/ExtensionInstanceDetails"
          ),
      ),
      pageTitle: lazy(
        () => import("../extensions/components/ExtensionInstanceTitle"),
      ),
    },
    linkId: "customerExtensionInstance",
  },
});
