import {
  FormController,
  useForm,
} from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import TextLink from "@mittwald/flow-components/dist/components/TextLink";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { Section as NewSection } from "@mittwald/flow-react-components";
import React, { FC } from "react";
import { Domain, NewDomainInputs } from "../../../../../../model/domain/Domain";
import { DomainList } from "../../../../../../model/domain/DomainList";
import DomainUI from "../../../../../../model/ui/domain/DomainUI";
import { DomainTransferAuthentication } from "../../../components/DomainTransferAuthentication/DomainTransferAuthentication";
import { PreviewDomainState } from "../CreateDomain";

interface Props {
  form: FormController<NewDomainInputs>;
  presetDomain?: string;
}

export const MoveDomainStep: FC<Props> = (props) => {
  const { form, presetDomain } = props;
  const { projectId } = usePathParams("projectId");
  const domainList = DomainList.useLoadAllByProjectId(projectId).useItems();

  const authCodeForm = useForm<Required<PreviewDomainState>>({
    defaultValues: {
      authCode: "",
      domain: presetDomain ?? "",
    },
    onSubmit: async (values) => {
      const { domain, authCode } = values;

      const domainRegistrable = await Domain.isAvailable(domain);

      if (domainRegistrable === "available") {
        authCodeForm.setError("domain", { message: "domainNotTransferable" });
        return false;
      }

      if (domainRegistrable !== "domainNotAvailable") {
        authCodeForm.setError("domain", { message: domainRegistrable });
        return false;
      }

      const authCodeCheck = await DomainUI.validateTransferAuthentication(
        DomainUI.normalizeDashes(domain),
        authCode,
      );
      if (!authCodeCheck.isValid) {
        const fieldName =
          authCodeCheck.authenticationType === "push" ||
          authCodeCheck.authenticationType === "email"
            ? "domain"
            : "authCode";
        authCodeForm.setError(fieldName, {
          message: authCodeCheck.error,
        });

        return false;
      }

      form.setValue("domain", domain);
      form.setValue("authCode", authCode);
    },
  });

  const domain = authCodeForm.watch("domain");

  return (
    <WizardStep
      form={authCodeForm}
      id="moveDomain"
      indicatorText="moveDomainStep"
    >
      <NewSection>
        <Section.Item headline="moveDomain">
          <Text i18n="moveDomainDescription" />
          <Text
            i18n={{
              id: "availableTlds",
              values: {
                domainPage: (
                  <TextLink
                    _inline
                    action="https://www.mittwald.de/produkte/domains"
                    i18n="domainPage"
                  />
                ),
              },
            }}
          />
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          {!presetDomain && (
            <TextField
              autoFocus
              label="hostname"
              name="domain"
              rules={{
                required: true,
                validate: (domain: string) =>
                  DomainUI.validateDomainAlreadyExists(
                    DomainUI.normalizeDashes(domain),
                    domainList,
                  ),
              }}
            />
          )}
          {presetDomain && (
            <StaticInformation label="domain" text={{ text: presetDomain }} />
          )}
          <DomainTransferAuthentication
            domain={domain}
            fieldNameAuthCode="authCode"
          />
        </Section.Item>
      </NewSection>
    </WizardStep>
  );
};

export default MoveDomainStep;
