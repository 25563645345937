import { getAvatarImageWidth } from "@mittwald/flow-components/dist/components/Avatar/lib";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import PriceBox from "@mittwald/flow-components/dist/components/PriceBox";
import { PriceTable } from "@mittwald/flow-components/dist/components/PriceTable";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import invariant from "invariant";
import React, { FC } from "react";
import { ConditionsCheckbox } from "../../../../../../../../components/ConditionsCheckbox/ConditionsCheckbox";
import SSLCertificate from "../../../../../../../../model/domain/ssl/SSLCertificate";
import MoneyValue from "../../../../../../../../model/misc/MoneyValue";
import { Project } from "../../../../../../../../model/project";
import { CustomerInfoBox } from "../../../../../../server/modals/OrderServerWizard/components/CustomerInfoBox";
import { HiddenPaymentInformation } from "../../../../../../server/modals/OrderServerWizard/components/HiddenPaymentInformation";
import CustomerEmailInformation from "../../../../../components/Order/CustomerEmailInformation/CustomerEmailInformation";
import { useOrderPreview } from "../../../../hooks/useOrderPreview";
import { usePrivateKeyState } from "../PrivateKeyStep/PrivateKeyStep";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { useCertificateStepState } from "../CertificateStep/CertificateStep";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface OverviewProps {}

export const OverviewStep: FC<OverviewProps> = () => {
  const project = Project.useLoadByPathParam();
  const customerId = project.customerId;

  const [privateKeyStepState] = usePrivateKeyState();
  const [certificateStepState] = useCertificateStepState();

  const sslRequestStateId = privateKeyStepState.sslRequestState?.id;
  const main = certificateStepState.linkedIngresses.slice(0, 4);
  const other = certificateStepState.linkedIngresses.slice(
    4,
    certificateStepState.linkedIngresses.length,
  );

  const form = useForm<{ conditions: boolean }>({
    defaultValues: {
      conditions: false,
    },
    onSubmit: async () => {
      invariant(sslRequestStateId, "missing request state from prev step");
      await SSLCertificate.order(sslRequestStateId, project.id);
    },
  });

  return (
    <TranslationProvider name="ssl.wizard.overview" type="component">
      <WizardStep form={form} id="overview" indicatorText="overviewStep">
        <Render>
          {() => {
            const [publicState] = useCertificateStepState();

            invariant(
              sslRequestStateId,
              "missing request state from prev step",
            );
            const orderPreview = useOrderPreview(sslRequestStateId);

            const totalPrice = new MoneyValue(
              orderPreview.totalPrice,
            ).toMoneyValueInput();

            const feePrice = new MoneyValue(
              orderPreview.feePrice,
            ).toMoneyValueInput();

            const recurringPrice = new MoneyValue(
              orderPreview.recurringPrice,
            ).toMoneyValueInput();

            const isCustomerMember = project.useMyMembership().data.inherited;
            const customerInfo = isCustomerMember ? (
              <CustomerInfoBox customerId={customerId} showContact={false} />
            ) : (
              <HiddenPaymentInformation />
            );
            const emailInformation = isCustomerMember ? (
              <CustomerEmailInformation customerId={customerId} />
            ) : (
              <Text i18n="description" />
            );

            const iconWidth = getAvatarImageWidth("m");

            return (
              <>
                <NewSection>
                  <Section.Item
                    headline="overview"
                    headlineIcon={iconFileCertificate}
                  >
                    {emailInformation}
                  </Section.Item>
                </NewSection>
                <NewSection>
                  <Section.Item>
                    <ColumnLayout medium={[1, 1]}>
                      <Box _itemGap="l">
                        {customerInfo}
                        {publicState.linkedIngresses.length >= 1 && (
                          <Box _itemGap="s" _mt="m">
                            <Box _flexDirection="row" _itemGap="m">
                              <Box
                                _flexDirection="row"
                                _justifyContent="center"
                                _width={iconWidth}
                              >
                                <Icon
                                  _color="brand-primary-dark"
                                  _size="m"
                                  icon={iconFileCertificate}
                                />
                              </Box>
                              <Box>
                                <Text i18n="domainsInfo" strong />
                                <Box>
                                  {main.map((i, key) => (
                                    <Text
                                      _mb="s"
                                      i18n={{ text: i.hostname }}
                                      key={key}
                                    />
                                  ))}
                                  {other.length >= 1 && (
                                    <Text
                                      i18n={{
                                        id: "otherDomains",
                                        values: { count: other.length },
                                      }}
                                      strong
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <H3 _mb="m" i18n="orderOverview" />
                        <PriceTable twoColumns>
                          <PriceTable.Row
                            monthlyPrice={recurringPrice}
                            monthlyPriceSubline="monthly"
                            singlePrice={feePrice}
                            singlePriceSubline="single"
                            strong={false}
                            title="article"
                          />
                          <Box _mt="m">
                            <PriceBox monthlyPrice={totalPrice} title="total" />
                          </Box>
                        </PriceTable>
                      </Box>
                    </ColumnLayout>
                    <ConditionsCheckbox />
                  </Section.Item>
                </NewSection>
              </>
            );
          }}
        </Render>
      </WizardStep>
    </TranslationProvider>
  );
};
