import { useFeatureEnabled } from "@mittwald/flow-lib/dist/hooks/useFeatureEnabled";
import React, { FC } from "react";
import { Project } from "../../../../model/project";
import { SideNavigationExtensions } from "./SideNavigationExtensions";

export const SideNavigationExtensionsForProject: FC = () => {
  const project = Project.useLoadByPathParam();

  const featureEnabled = useFeatureEnabled("showRemoteExtensionMenu");
  if (!featureEnabled) {
    return null;
  }

  return (
    <SideNavigationExtensions
      context="project"
      fragmentPath="/projects/project/menu/section/extensions/item"
      identifier={project.id}
      linkId="projectExtensionFragmentView"
    />
  );
};
