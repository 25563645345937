import React, { FC } from "react";
import Customer from "../../../../model/customer/Customer";
import { SideNavigationExtensions } from "./SideNavigationExtensions";

export const SideNavigationExtensionsForCustomer: FC = () => {
  const customer = Customer.useLoadByPathParam();
  return (
    <SideNavigationExtensions
      context="customer"
      fragmentPath="/customers/customer/menu/section/extensions/item"
      identifier={customer.id}
      linkId="customerExtensionFragmentView"
    />
  );
};
