import { mittwaldApi } from "../api/Mittwald";

const requiredEmailEndings = ["@mittwald.de"];

/**
 * Check if the logged in user has a @mittwald.de email address
 */
export const useCheckIsEmployee = (): boolean => {
  const email = mittwaldApi.userGetUser
    .getResource({
      path: { userId: "self" },
    })
    .useWatchData().email;

  return requiredEmailEndings.some((ending) => !!email?.endsWith(ending));
};

export default useCheckIsEmployee;
