import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPublicKey } from "@mittwald/flow-icons/dist/publicKey";
import React, { FC } from "react";
import Domain from "../../../../../model/domain/Domain";
import DomainUI from "../../../../../model/ui/domain/DomainUI";

interface Props {
  domain: Domain;
}

export const MoveDomainAuthInfoCorrection: FC<Props> = (props) => {
  const { domain } = props;
  const visibility = useVisibilityController();

  const form = useForm({
    defaultValues: {
      authCode: "",
    },
    onSubmit: async (values) => {
      const authCodeValidation = await DomainUI.validateTransferAuthentication(
        domain.domain,
        values.authCode,
      );
      if (!authCodeValidation.isValid) {
        form.setError("authCode", {
          message: authCodeValidation.error,
        });
        return false;
      }

      return domain.moveDomainAuthInfoCorrection(values.authCode);
    },
  });

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="moveDomainAuthInfoCorrection"
      headlineIcon={iconPublicKey}
      primary={{
        action: [{ form }, visibility.hide],
        text: "correctAuthCode",
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <TextField
            label="authCode"
            name="authCode"
            rules={{
              required: true,
              validate: {
                unchangedAuthCode: (v) =>
                  domain.transferInAuthCode
                    ? domain.transferInAuthCode !== v
                    : true,
              },
            }}
          />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default MoveDomainAuthInfoCorrection;
