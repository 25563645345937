import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import React, { FC } from "react";
import Ingress from "../../../../../../../../model/domain/Ingress";
import { IngressList } from "../../../../../../../../model/domain/IngressList";
import { checkHostnameMatchesWildcards } from "../../../../utils/checkHostnameMatchesWildcards";
import { EditCertificateProps } from "../../EditCertificate";
import { useCertificateStepState } from "../CertificateStep/CertificateStep";
import { usePrivateKeyState } from "../PrivateKeyStep/PrivateKeyStep";

export const DifferencesStep: FC<EditCertificateProps> = (props) => {
  const { certificate } = props;
  const project = certificate.useProject();

  return (
    <WizardStep id="differences" indicatorText="differencesStep">
      <Render>
        {() => {
          const [privateKeyStepState] = usePrivateKeyState();
          const [certificateStepState] = useCertificateStepState();

          const newCompatibleIngresses =
            IngressList.useIngressesCompatibleWithCertificate(
              project.id,
              certificateStepState.certificateData,
            ).useItems();

          const newHostnames = [
            privateKeyStepState.diff.commonName?.newValue,
            ...(privateKeyStepState.diff.dnsNames?.addedValues ?? []),
            ...(privateKeyStepState.diff.dnsNames?.values ?? []),
          ]
            .filter((i): i is string => !!i)
            .filter((i, pos, array): i is string => array.indexOf(i) == pos)
            .filter((i) =>
              newCompatibleIngresses.find((a) => a.hostname === i),
            );

          const oldCompatibleIngresses =
            IngressList.useIngressesCompatibleWithCertificate(
              project.id,
              certificate.certificate,
            )
              .filterExternalCertificates()
              .useItems()
              .filter(
                (ingress) =>
                  ingress.ssl.type === "externalSSL" &&
                  ingress.ssl.certificateId === certificate.id,
              )
              .filter(
                (ingress): ingress is Ingress =>
                  !checkHostnameMatchesWildcards(
                    ingress.hostname,
                    newHostnames,
                  ),
              );

          //if no changes in dnsNames, push hostnames of oldCompatibleIngresses into new hostnames array
          if (!privateKeyStepState.diff.dnsNames) {
            const hostnames = oldCompatibleIngresses.map((i) => i.hostname);
            newHostnames.push(...hostnames);
          }

          //if dnsNames have changes, add old items to removeOldIngresses array
          const removedOldIngresses = !privateKeyStepState.diff.dnsNames
            ? []
            : oldCompatibleIngresses.map((ingress) => ingress.hostname);

          //if commonName has changes, add old common name to removeOldCommenName array
          const removedOldCommonName = !privateKeyStepState.diff.commonName
            ? []
            : [privateKeyStepState.diff.commonName.oldValue];

          const removedHostnames = [
            ...removedOldIngresses,
            ...removedOldCommonName,
            ...(privateKeyStepState.diff.dnsNames?.removedValues ?? []),
          ]
            .filter((i): i is string => !!i)
            .filter((i, pos, array): i is string => array.indexOf(i) === pos);

          const ingressWillReceiveCertificate = newCompatibleIngresses.filter(
            (i) =>
              checkHostnameMatchesWildcards(i.hostname, newHostnames) &&
              ((i.ssl.type === "externalSSL" &&
                i.ssl.certificateId === certificate.id) ||
                i.ssl.type === "acme" ||
                !i.ssl.hasSSL),
          );

          const ingressWillBeDowngradedToLetsEncrypt = [
            ...newCompatibleIngresses,
            ...oldCompatibleIngresses,
          ]
            .filter(
              (i) =>
                checkHostnameMatchesWildcards(i.hostname, removedHostnames) &&
                i.ssl.type === "externalSSL" &&
                i.ssl.certificateId === certificate.id,
            )
            .filter(
              (ingress, pos, array): ingress is Ingress =>
                array.findIndex((i) => i.hostname === ingress.hostname) === pos,
            );

          const added = ingressWillReceiveCertificate.map((ingress) => (
            <TextWithIconLayout
              icon={iconDomain}
              iconProps={{ _size: "s" }}
              key={`added-${ingress.id}`}
            >
              <Text
                i18n={{
                  text: ingress.hostname,
                }}
              />
            </TextWithIconLayout>
          ));

          const removed = ingressWillBeDowngradedToLetsEncrypt.map(
            (ingress) => (
              <TextWithIconLayout
                icon={iconDomain}
                iconProps={{ _size: "s" }}
                key={`removed-${ingress.id}`}
              >
                <Text
                  i18n={{
                    text: ingress.hostname,
                  }}
                />
              </TextWithIconLayout>
            ),
          );

          return (
            <Section.Layout _pl="xxs">
              {added.length === 0 && (
                <Section.Item headline="noIngress">
                  <Text i18n="noIngress.description" />
                  <ColumnLayout large={[1, 1, 1]} medium={[1, 1]} small={[1]}>
                    {newHostnames.length >= 1 &&
                      newHostnames.map((name) => (
                        <TextWithIconLayout
                          icon={iconDomain}
                          iconProps={{ _size: "s" }}
                          key={`newhostnames-${name}`}
                        >
                          <Text
                            i18n={{
                              text: name,
                            }}
                          />
                        </TextWithIconLayout>
                      ))}
                  </ColumnLayout>
                </Section.Item>
              )}
              {added.length >= 1 && (
                <Section.Item headline="added">
                  <ColumnLayout large={[1, 1, 1]} medium={[1, 1]} small={[1]}>
                    {added}
                  </ColumnLayout>
                </Section.Item>
              )}
              {removed.length >= 1 && (
                <Section.Item headline="removed">
                  <ColumnLayout large={[1, 1, 1]} medium={[1, 1]} small={[1]}>
                    {removed}
                  </ColumnLayout>
                </Section.Item>
              )}
            </Section.Layout>
          );
        }}
      </Render>
    </WizardStep>
  );
};
