import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import CrontabInterval from "@mittwald/flow-components/dist/components/CrontabInterval";
import { FormController } from "@mittwald/flow-components/dist/components/Form";
import Select from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";

import React, { FC, useEffect } from "react";
import ScheduleUI, {
  ScheduleTimeInterval,
} from "../../model/ui/misc/ScheduleUI";
import { ClimateBox } from "./ ClimateBox";

interface Props {
  form: FormController<any>;
  intervalFormFieldName?: string;
  crontabFormFieldName?: string;
  intervals: ScheduleTimeInterval[];
  onlyAllowTimeSelection?: boolean;
}
export const ScheduleInputs: FC<Props> = (props) => {
  const {
    form,
    intervalFormFieldName = "interval",
    crontabFormFieldName = "schedule",
    intervals,
    onlyAllowTimeSelection = false,
  } = props;

  const [interval, time, schedule] = form.watch([
    intervalFormFieldName,
    "time",
    crontabFormFieldName,
  ]);

  const isSmallIntervall = ScheduleUI.isSmallIntervall(schedule);

  useEffect(() => {
    const newSchedule = ScheduleUI.updateScheduleWithTime(schedule, time);
    if (newSchedule) {
      form.setValue(crontabFormFieldName, newSchedule);
    }
  }, [time]);

  useEffect(() => {
    const newSchedule = ScheduleUI.updateScheduleWithInterval(
      schedule,
      interval,
    );
    if (newSchedule) {
      form.setValue(crontabFormFieldName, newSchedule);
    }
  }, [interval]);

  useEffect(() => {
    if (schedule.replace(/\s+/g, " ").trim().split(" ").length === 5) {
      const newInterval = ScheduleUI.getIntervalFromSchedule(schedule);
      if (newInterval !== interval) {
        form.setValue(intervalFormFieldName, newInterval);
      }
      const newTime = ScheduleUI.getTimeFromSchedule(schedule);
      if (newTime !== time) {
        form.setValue("time", newTime);
      }
    }
  }, [schedule]);

  return (
    <TranslationProvider name="scheduleInputs" type="component">
      <ColumnLayout medium={[1, 1]}>
        <Select
          disabled={onlyAllowTimeSelection}
          label="interval"
          name={intervalFormFieldName}
          options={ScheduleUI.getIntervalOptions(intervals)}
          rules={{ required: true }}
        />
        <Select
          disabled={interval === "1h" || (interval && interval.endsWith("m"))}
          label="time"
          name="time"
          options={ScheduleUI.getTimeOptions()}
          rules={{ required: true }}
        />
        <CrontabInterval
          disabled={onlyAllowTimeSelection}
          futureExecutionsPreviewCount={3}
          label="cronSyntax"
          name={crontabFormFieldName}
          rules={{ required: true }}
        />
      </ColumnLayout>
      {isSmallIntervall && <ClimateBox />}
    </TranslationProvider>
  );
};
