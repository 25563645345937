import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconSshUser } from "@mittwald/flow-icons/dist/sshUser";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { SshUser } from "../../../../../model/access/SshUser";
import { NewSshUserInputs } from "../../../../../model/access/types";
import Section from "@mittwald/flow-components/dist/components/Section";
import { SecuritySection } from "../../components/SecuritySection";
import { Section as NewSection } from "@mittwald/flow-react-components";

export const CreateSshUser: FC = () => {
  const { projectId } = usePathParams("projectId");
  const goto = useGotoLink();
  const visibility = useVisibilityController(true);

  const form = useForm<NewSshUserInputs, string>({
    translationKey: "createSshUser",
    defaultValues: {
      key: "",
      password: "",
      comment: "",
      description: "",
      authType: "publicKey",
    },
    onSubmit: async (values) => {
      const result = await SshUser.createNew(values, projectId);

      if (!result) {
        form.setError("key", {
          message: "invalidKey",
        });
        return false;
      }

      goto("sshDetails", { sshUserId: result.id });
    },
  });

  return (
    <Modal name="create" visibility={visibility}>
      <ModalContent>
        <Form controller={form}>
          <NewSection>
            <Section.Item headline="createSshUser" headlineIcon={iconSshUser}>
              <Text i18n="createDescription" />
              <ColumnLayout medium={[1, 1]}>
                <TextField
                  autoFocus
                  label="description"
                  name="description"
                  rules={{ required: true }}
                />
                <DatePicker
                  infoMessage={{ message: "expiresAtHint" }}
                  label="expiresAt"
                  name="expiresAt"
                  whitelist={{ fromDate: DateTime.local().plus({ days: 1 }) }}
                />
              </ColumnLayout>
            </Section.Item>
          </NewSection>
          <NewSection>
            <SecuritySection />
          </NewSection>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          action: [{ form }, visibility.hide],
          ok: true,
          text: "createUser",
        }}
        visibility={visibility}
      />
    </Modal>
  );
};

export default CreateSshUser;
