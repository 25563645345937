import { Box } from "@mittwald/flow-components/dist/components/Box";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { FC } from "react";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconSettings } from "@mittwald/flow-icons/dist/settings";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import Blog from "../../../../../model/blog/Blog";
import Dashboard, {
  DashboardSettingsData,
} from "../../../../../model/dashboard/Dashboard";
import ChangelogModal from "../../../profile/components/ChangelogModal/ChangelogModal";
import DashboardSetting from "./components/DashboardSetting";

const DashboardSettings: FC = () => {
  const visibility = useVisibilityController();
  const buildLink = useLinkBuilder();
  const latestArticle = Blog.useLatestArticle();

  const form = useForm<DashboardSettingsData>({
    defaultValues: {
      firstSteps: !Dashboard.isDisabled("firstSteps"),
      todos: !Dashboard.isDisabled("todos"),
      support: !Dashboard.isDisabled("support"),
      productSlider: !Dashboard.isDisabled("productSlider"),
      features: !Dashboard.isDisabled("features"),
      blog: !Dashboard.isDisabled("blog"),
      debit: !Dashboard.isDisabled("debit"),
      status: !Dashboard.isDisabled("status"),
      newsletter: !Dashboard.isDisabled("newsletter"),
    },
    onSubmit: (values) => {
      Dashboard.updateSettings(values);
    },
  });

  return (
    <DefaultModal
      description="dashboardSettingsDescription"
      headline="dashboardSettings"
      headlineIcon={iconSettings}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <Box _itemGap="l">
          <DashboardSetting
            link={[visibility.hide, buildLink("onboarding")]}
            name="firstSteps"
          />
          <DashboardSetting name="status" />
          <DashboardSetting name="productSlider" />
          <DashboardSetting
            description={{
              id: "features.description",
              values: {
                roadmap: (
                  <TextLink
                    action="https://www.mittwald.de/roadmap"
                    i18n="roadmap"
                  />
                ),
              },
            }}
            link={() => showModal("changelog", <ChangelogModal />)}
            name="features"
          />
          <DashboardSetting link={latestArticle?.link} name="blog" />
          <DashboardSetting name="debit" />
          <DashboardSetting name="newsletter" />
        </Box>
      </Form>
    </DefaultModal>
  );
};

export default DashboardSettings;
