import { arrayAccessor } from "@mittwald/awesome-node-utils/misc/ArrayAccessor";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import MySqlVersion from "./MySqlVersion";

export class MySqlVersionList extends ListModel<MySqlVersion> {
  public readonly latestVersion?: string;

  public constructor(data: MySqlVersion[]) {
    super(data);
    this.latestVersion = arrayAccessor(this.items).optionalFirst?.number;
  }

  public static useLoadAllByProjectId(projectId: string): MySqlVersionList {
    const versions = mittwaldApi.databaseListMysqlVersions
      .getResource({ query: { projectId } })
      .useWatchData()
      .filter((d) => !d.disabled)
      .map((d) => MySqlVersion.fromApiData(d))
      .reverse();

    return new MySqlVersionList(versions);
  }
}
