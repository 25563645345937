import {
  PrimaryActionBarAction,
  SecondaryActionBarAction,
} from "@mittwald/flow-components/dist/components/ActionBar";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import ValidationErrorMessage from "@mittwald/flow-components/dist/components/ValidationErrorMessage";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { ActionResult } from "@mittwald/flow-components/dist/lib/actions/BaseAction";
import { FunctionActionFn } from "@mittwald/flow-components/dist/lib/actions/FunctionAction";
import { iconForwardAddress } from "@mittwald/flow-icons/dist/forwardAddress";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { isEmail } from "@mittwald/flow-lib/dist/validation";
import React, { FC, useEffect, useRef, useState } from "react";
import { IngressList } from "../../../../../model/domain/IngressList";
import {
  EmailAddress,
  NewForwardAddressInputs,
} from "../../../../../model/mail/EmailAddress";
import { EmailAddressList } from "../../../../../model/mail/EmailAddressList";
import { EmailListInput } from "../../../components/EmailListInput";
import CatchAllExistsConfirmation from "../../addresses/components/CatchAllExistsConfirmation";
import EmailAddressForm from "../../addresses/components/EmailAddressForm";
import { Section as NewSection } from "@mittwald/flow-react-components";

export const CreateForwardAddress: FC = () => {
  const { projectId } = usePathParams("projectId");
  const goto = useGotoLink();
  const lastCreatedId = useRef<string>(null);
  const createMore = useRef<boolean>(null);
  const [noForwards, setNoForwards] = useState(false);
  const visibility = useVisibilityController();

  const emailAddressList = EmailAddressList.useLoadAllByProjectId(projectId);

  const emails: string[] = emailAddressList
    .useItems()
    .map((email) => email.address);

  const enabledIngresses =
    IngressList.useLoadAllByProjectId(projectId).useEnabledIngressList();

  const form = useForm<NewForwardAddressInputs>({
    translationKey: "addForward",
    resetFormOnSuccessWithNewValues: false,
    defaultValues: {
      address: "",
      forwardAddresses: [],
    },
    onSubmit: async (values) => {
      if (emailAddressList.addressExists(values.address)) {
        form.setError("address", {
          message: "addressExists",
        });
        return false;
      }

      const domain = EmailAddress.getEmailAddressDomainPart(values.address);

      const existingCatchAllAddress =
        emailAddressList.findCatchAllAddressForDomain(domain);

      if (existingCatchAllAddress && values.isCatchAll) {
        await existingCatchAllAddress.updateCatchAll(false);
      }

      const result = await EmailAddress.createNewForwardAddress(
        values,
        projectId,
      );

      if (result === false) {
        return false;
      }

      lastCreatedId.current = result;
    },
  });

  const [watchedForwardAddresses, isCatchAll, address] = form.watch([
    "forwardAddresses",
    "isCatchAll",
    "address",
  ]);

  const domain = EmailAddress.getEmailAddressDomainPart(address);
  const existingCatchAllAddress =
    emailAddressList.findCatchAllAddressForDomain(domain);

  const beforeSubmit: FunctionActionFn = () => {
    const noForwards = watchedForwardAddresses.length <= 0;
    setNoForwards(noForwards);
    return noForwards ? ActionResult.aborted : ActionResult.success;
  };

  const afterSubmit = (): void => {
    if (!createMore.current) {
      visibility.hide();
      if (lastCreatedId.current) {
        goto(
          "forward",
          { emailAddressId: lastCreatedId.current },
          { replace: true },
        );
      }
      return;
    }
    createMore.current = false;
    form.setFocus("address");
    form.reset();
  };

  useEffect(() => {
    if (watchedForwardAddresses.length >= 1) {
      setNoForwards(false);
    }
  }, [watchedForwardAddresses]);

  const newForwardPrimaryAction: PrimaryActionBarAction = {
    text: "create",
    ok: true,
    action: [beforeSubmit, { form }, afterSubmit],
  };

  const newForwardSecondaryAction: SecondaryActionBarAction = {
    text: "createAndNew",
    action: [
      beforeSubmit,
      () => {
        createMore.current = true;
      },
      { form },
      afterSubmit,
    ],
  };

  const ingressesAvailable = enabledIngresses.items.length > 0;

  return (
    <DefaultModal
      description="description"
      headline="createForwardAddress"
      headlineIcon={iconForwardAddress}
      primary={newForwardPrimaryAction}
      secondary={newForwardSecondaryAction}
      visibility={visibility}
    >
      <Form controller={form}>
        <NewSection>
          <Section.Item>
            <EmailAddressForm
              autoFocus
              catchAll={isCatchAll}
              ingressList={enabledIngresses}
              name="address"
            />
            <EmailListInput
              autocompleteEmails={emails}
              disabled={!ingressesAvailable}
              label="labelNewForwardAddress"
              name="forwardAddresses"
            />
            {noForwards && <ValidationErrorMessage i18n="atLeastOneForward" />}
          </Section.Item>
        </NewSection>
        <NewSection>
          <Section.Item headline="additionalOptions">
            <CheckBox
              checkConfirmationOptions={{
                required: !isCatchAll && !!existingCatchAllAddress,
                modalComponent: CatchAllExistsConfirmation,
                modalTranslationValues: {
                  existingCatchAllAddress: existingCatchAllAddress?.address,
                },
                modalTranslationKey: "enableCatchAll",
              }}
              disabled={!isEmail(address)}
              name="isCatchAll"
              title="labelCatchAll"
            />
          </Section.Item>
        </NewSection>
      </Form>
    </DefaultModal>
  );
};

export default CreateForwardAddress;
