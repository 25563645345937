import { useIsActiveRoute } from "@mittwald/flow-lib/dist/hooks/useIsActiveRoute";
import { usePageTreeContext } from "@mittwald/flow-lib/dist/hooks/usePageTreeContext";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import { generatePath, useResolvedPath } from "react-router-dom";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import InlineNavigation from "@mittwald/flow-components/dist/components/InlineNavigation";

import Extension from "../../../../../model/marketplace/Extension";
import ExtensionInstance from "../../../../../model/marketplace/ExtensionInstance";

interface NavigationExtensionItemProps {
  extensionInstance: ExtensionInstance;
  fragmentPath: string;
  linkId: string;
}

const ExtensionItem: FC<{
  extension: Extension;
  extensionInstance: ExtensionInstance;
  fragment: string;
  linkId: string;
}> = (props) => {
  const { extension, extensionInstance, fragment, linkId } = props;
  const isCompact = useCompactMode();

  const currentParams = usePathParams();
  const context = usePageTreeContext();
  const detailExtensionViewPath = context.pageTree.getPath(linkId);
  const { title = { de: extension.name } } =
    extension.frontendFragments[fragment]?.additionalProperties ?? {};

  const pathParams = {
    ...currentParams,
    extensionInstanceId: extensionInstance.id,
    extensionFragment: fragment,
  };

  const resolvedPath = useResolvedPath(
    generatePath(detailExtensionViewPath, pathParams),
  );

  const isActive = useIsActiveRoute(resolvedPath.pathname);

  return (
    <InlineNavigation.Item
      _bgColor={isCompact ? "default" : undefined}
      _color={undefined}
      _internal={{ active: isActive }}
      action={{
        link: {
          id: linkId,
          options: {
            pathParams,
            queryParams: {
              fragment: window.btoa(fragment),
            },
          },
        },
      }}
      i18n={{ text: typeof title === "string" ? title : title.de }}
    />
  );
};

export const TopNavigationExtensionItem: FC<NavigationExtensionItemProps> = (
  props,
) => {
  const { extensionInstance, fragmentPath, linkId } = props;
  const extension = Extension.useLoadById(extensionInstance.extensionId);

  if (!extension.hasFrontendFragment(fragmentPath)) {
    return null;
  }

  return (
    <ExtensionItem
      extension={extension}
      extensionInstance={extensionInstance}
      fragment={fragmentPath}
      linkId={linkId}
    />
  );
};
