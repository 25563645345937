import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { iconSftpUser } from "@mittwald/flow-icons/dist/sftpUser";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { SecuritySection } from "../../../components/SecuritySection";
import { Section as NewSection } from "@mittwald/flow-react-components";

export const DescriptionStep: FC = () => {
  return (
    <WizardStep id="description" indicatorText="setDescription">
      <NewSection>
        <Section.Item headline="newSftpUser" headlineIcon={iconSftpUser}>
          <Text i18n="newSftpUserDescription" />
          <ColumnLayout medium={[1, 1]}>
            <TextField
              autoFocus
              label="description"
              name="description"
              rules={{ required: true }}
            />
            <DatePicker
              infoMessage={{ message: "expiresAtHint" }}
              label="expiresAt"
              name="expiresAt"
              whitelist={{ fromDate: DateTime.local().plus({ days: 1 }) }}
            />
          </ColumnLayout>
        </Section.Item>
      </NewSection>
      <NewSection>
        <SecuritySection />
      </NewSection>
    </WizardStep>
  );
};

export default DescriptionStep;
