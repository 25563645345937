import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconLink } from "@mittwald/flow-icons/dist/link";
import invariant from "invariant";
import React from "react";
import CreateDomain from "../../../pages/app/domain/modals/CreateDomain/CreateDomain";
import CreatePath from "../../../pages/app/domain/modals/CreatePath/CreatePath";
import { DeleteDomain } from "../../../pages/app/domain/modals/DeleteDomain/DeleteDomain";
import { DeleteIngress } from "../../../pages/app/domain/modals/DeleteIngress/DeleteIngress";
import MoveDomain from "../../../pages/app/domain/modals/MoveDomain/MoveDomain";
import MoveDomainAuthInfoCorrection from "../../../pages/app/domain/modals/MoveDomainAuthInfoCorrection/MoveDomainAuthInfoCorrection";
import Domain from "../../domain/Domain";
import Ingress from "../../domain/Ingress";
import { IngressPath } from "../../domain/IngressPath";
import { Role } from "../acl/Role";
import { bankruptcyCheckedAction } from "../BankruptGuard";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultIngressRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: Ingress,
  method: "delete",
  roles: defaultIngressRoles,
  isAvailable: (ingress) => !ingress.isDefault,
  altAction: (action) => () =>
    showModal("deleteIngress", <DeleteIngress ingress={action.model} />),
})
  .register({
    model: Ingress,
    method: "moveDomain",
    roles: defaultIngressRoles,
    isAvailable: (ingress) => {
      if (ingress.isSubdomain || !ingress.domain) {
        return false;
      }
      return ingress.domain.processes.isEmpty;
    },
    altAction: (action) => () => {
      invariant(action.model.domain, "domain not found");
      showModal("moveDomain", <MoveDomain domain={action.model.domain} />);
    },
  })
  .register({
    model: Domain,
    method: "moveDomainAuthInfoCorrection",
    roles: defaultIngressRoles,
    isAvailable: (domain) =>
      domain.processes.isAuthCodeMismatchError() ||
      domain.processes.hasFailedTransfer(),
    altAction: (action) => ({
      action: () => {
        invariant(action.model, "domain not found");
        showModal(
          "moveDomainAuthInfoCorrection",
          <MoveDomainAuthInfoCorrection domain={action.model} />,
        );
      },
      successFeedback: "never",
    }),
  })
  .register({
    model: Domain,
    method: "moveDomainAbort",
    roles: defaultIngressRoles,
    isAvailable: (domain) => domain.processes.isAuthCodeMismatchError(),
    altAction: (action) => ({
      actions: [action],
      confirmation: {
        required: true,
        modalTranslationKey: "moveDomainAbort",
        modalProps: {
          headlineIcon: faXmark,
        },
        modalTranslationValues: {
          domain: action.model.data.domain,
        },
      },
    }),
  })
  .register({
    model: Ingress,
    method: "createNew",
    roles: defaultIngressRoles,
    altAction: () => () => showModal("createDomain", <CreateDomain />),
  })
  .register({
    model: Ingress,
    method: "moveIngress",
    roles: defaultIngressRoles,
    isAvailable: (ingress) => ingress.useIsAvailableForMove(),
    altAction: (action) => ({
      action: () => {
        showModal(
          "createDomain",
          <CreateDomain presetDomainForMove={action.model.data.hostname} />,
        );
      },
    }),
  })
  .register({
    model: Ingress,
    method: "updatePaths",
    roles: defaultIngressRoles,
    altAction: (action) => () =>
      showModal("createPath", <CreatePath ingress={action.model} />),
  })
  .register({
    model: Domain,
    method: "delete",
    roles: defaultIngressRoles,
    isAvailable: (domain) => !domain.processes.isAuthCodeMismatchError(),
    altAction: (action) =>
      bankruptcyCheckedAction(action.model, () =>
        showModal("deleteDomain", <DeleteDomain domain={action.model} />),
      ),
  })
  .register({
    model: IngressPath,
    method: "delete",
    isAvailable: (ingressPath) => {
      if (ingressPath.ingress.domain) {
        return !ingressPath.ingress.domain.processes.isAuthCodeMismatchError();
      }
      return true;
    },
    roles: defaultIngressRoles,
    altAction: (action) => ({
      actions: [
        action,
        {
          link: {
            id: "domainPaths",
            options: {
              replace: true,
            },
          },
        },
      ],
      confirmation: {
        required: true,
        modalTranslationKey: "deleteDomainPath",
        modalProps: {
          headlineIcon: iconDelete,
        },
        modalTranslationValues: {
          path: action.model.path,
        },
      },
    }),
  })
  .register({
    model: Ingress,
    method: "setNewCertificate",
    roles: defaultIngressRoles,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "linkCertificate",
        modalProps: {
          headlineIcon: iconLink,
          description: {
            id: "description",
            values: { domain: action.model.hostname },
          },
          primary: {
            ok: true,
            destructive: false,
          },
        },
      },
    }),
  })
  .register({
    model: Ingress,
    method: "setAcmeCertificate",
    roles: defaultIngressRoles,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "unlinkCertificate",
        modalProps: {
          headlineIcon: iconLink,
          description: {
            id: "description",
            values: { domain: action.model.hostname },
          },
        },
      },
    }),
  });
