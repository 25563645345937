import React, { FC } from "react";
import FlowNavigation, {
  NavigationSections,
} from "@mittwald/flow-components/dist/components/Navigation";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { buildRendererTranslationKey } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/i18n/buildRendererTranslationKey";
import styled from "styled-components";
import { MarketplaceContext } from "../../../../model/marketplace/Extension";
import ExtensionInstanceList from "../../../../model/marketplace/ExtensionInstanceList";
import { NavigationExtensionItem } from "./components/NavigationExtensionItem";

interface SideNavigationExtensionsProps {
  context: MarketplaceContext;
  identifier: string;
  fragmentPath: string;
  linkId: string;
}

export const Navigation = styled(FlowNavigation)`
  display: none;

  &:not(:has(label + div:empty)) {
    display: block;
  }
`;

export const SideNavigationExtensions: FC<SideNavigationExtensionsProps> = (
  props,
) => {
  const { context, identifier, fragmentPath, linkId } = props;

  const instances = ExtensionInstanceList.useLoadAll(
    context,
    identifier,
  ).useItems();

  const sectionItems = instances.map((extensionInstance) => (
    <NavigationExtensionItem
      extensionInstance={extensionInstance}
      fragmentPath={fragmentPath}
      key={extensionInstance.id}
      linkId={linkId}
    />
  ));

  const navSections: NavigationSections = [
    {
      title: [
        joinTranslationKeys("menu", "section", "loadedExtensions"),
        buildRendererTranslationKey("menu.section.other"),
      ],
      items: sectionItems,
    },
  ];

  return <Navigation sections={navSections} />;
};
