import { arrayAccessor } from "@mittwald/awesome-node-utils/misc/ArrayAccessor";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import RedisVersion from "./RedisVersion";

export class RedisVersionList extends ListModel<RedisVersion> {
  public readonly latestVersion?: string;

  public constructor(data: RedisVersion[]) {
    super(data);
    this.latestVersion = arrayAccessor(this.items).optionalFirst?.number;
  }

  public static useLoadAllByProjectId(projectId: string): RedisVersionList {
    const versions = mittwaldApi.databaseListRedisVersions
      .getResource({ query: { projectId } })
      .useWatchData()
      .filter((d) => !d.disabled)
      .map((d) => RedisVersion.fromApiData(d))
      .reverse();

    return new RedisVersionList(versions);
  }
}
