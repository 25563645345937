import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import { iconInvite } from "@mittwald/flow-icons/dist/invite";
import { iconMembers } from "@mittwald/flow-icons/dist/members";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconSettings } from "@mittwald/flow-icons/dist/settings";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import Project from "../../../model/project/Project";
import SpaceServerProject from "../../../model/project/SpaceServerProject";
import { SideNavigationExtensionsForProject } from "../extensions/navigation/SideNavigationExtensionsForProject";
import ProjectSideBarHeader, {
  ProjectSidebarHeaderLoading,
} from "./components/ProjectSideBarHeader";

export const projectPages = PageTree.build(BacksideRenderer, {
  "/app/projects": {
    sector: "basics",
    views: {
      main: lazy(() => import("./overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(SpaceServerProject, "createNew"),
          },
          title: "createProject",
          icon: iconProject,
        },
      ],
    },
    linkId: "projects",
    icon: iconProject,
  },
  "/app/projects/:projectId": {
    views: {
      sideMenuNavigationBottom: SideNavigationExtensionsForProject,
      sidebarSectionHeader: ProjectSideBarHeader,
      sidebarSectionHeaderLoading: ProjectSidebarHeaderLoading,
    },
    linkId: "project",
    props: {
      sideNav: true,
    },
  },

  "/app/projects/:projectId/members": {
    props: {
      navigationSection: "management",
      tabNav: true,
    },
    icon: iconMembers,
    linkId: "projectMembers",
    accessCheck: () =>
      Project.useLoadByPathParam().useCheckMyRoleIsIn(["owner", "external"]),
  },
  "/app/projects/:projectId/members/members": {
    views: {
      main: lazy(() => import("./members/members")),
    },
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Project.useLoadByPathParam(),
                "inviteMember",
              ),
          },
          title: "inviteProjectMember",
          icon: iconInvite,
        },
      ],
    },
    icon: iconMembers,
    linkId: "projectMembersList",
  },
  "/app/projects/:projectId/members/invites": {
    views: {
      main: lazy(() => import("./members/invites")),
    },
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Project.useLoadByPathParam(),
                "inviteMember",
              ),
          },
          title: "inviteProjectMember",
          icon: iconInvite,
        },
      ],
    },
    icon: iconInvite,
    linkId: "projectInvitesList",
    accessCheck: () => Project.useLoadByPathParam().useCheckMyRoleIs("owner"),
  },

  "/app/projects/:projectId/members/members/:membershipId": {
    views: {
      main: lazy(() => import("./members/member")),
      pageTitle: lazy(() => import("./components/MemberName")),
    },
    icon: iconMembers,
    linkId: "projectMemberDetails",
  },

  "/app/projects/:projectId/extensions": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceOverview/ExtensionInstanceOverviewForProject"
          ),
      ),
    },
    props: {
      navigationSection: "management",
      beta: true,
    },
    icon: iconExtension,
    linkId: "projectExtensions",
  },

  "/app/projects/:projectId/extensions/:extensionInstanceId": {
    views: {
      pageTitle: lazy(
        () => import("../extensions/components/ExtensionInstanceTitle"),
      ),
    },
    linkId: "projectExtensionInstance",
    props: {
      tabNav: true,
    },
  },
  "/app/projects/:projectId/extensions/:extensionInstanceId/extension": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceDetails/ExtensionInstanceDetails"
          ),
      ),
    },
  },
  "/app/projects/:projectId/extensions/:extensionInstanceId/contributor": {
    views: {
      main: lazy(
        () =>
          import("../extensions/pages/ContributorDetails/ContributorDetails"),
      ),
    },
  },

  "/app/projects/:projectId/settings": {
    views: {
      main: lazy(() => import("./settings/settings")),
    },
    icon: iconSettings,
    props: {
      navigationSection: "management",
    },
    linkId: "projectSettings",
  },
});
