import {
  PrimaryActionBarAction,
  SecondaryActionBarAction,
} from "@mittwald/flow-components/dist/components/ActionBar";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC, useRef } from "react";
import { iconDeliveryBox } from "@mittwald/flow-icons/dist/deliveryBox";
import {
  DeliveryBox,
  NewDeliveryBoxInputs,
} from "../../../../../model/mail/DeliveryBox";

export const CreateDeliveryBox: FC = () => {
  const goto = useGotoLink();
  const { projectId } = usePathParams("projectId");
  const lastCreatedId = useRef<string>(null);
  const createMore = useRef<boolean>(null);
  const visibility = useVisibilityController();

  const defaultValues = {
    description: "",
    password: "",
  };

  const form = useForm<NewDeliveryBoxInputs>({
    defaultValues,
    translationKey: "createDeliveryBox",
    onSubmit: async (values) => {
      lastCreatedId.current = await DeliveryBox.createNew(values, projectId);
    },
  });

  const afterSubmit = (): void => {
    if (!createMore.current) {
      visibility.hide();
      if (lastCreatedId.current) {
        goto(
          "deliverybox",
          { deliveryboxId: lastCreatedId.current },
          { replace: true },
        );
      }
      createMore.current = false;
      return;
    }
    createMore.current = false;
    form.setFocus("description");
    form.reset(defaultValues);
  };

  const primary: PrimaryActionBarAction = {
    text: "create",
    ok: true,
    action: [{ form }, afterSubmit],
  };

  const secondary: SecondaryActionBarAction = {
    text: "createAndNew",
    action: [
      () => {
        createMore.current = true;
      },
      { form },
      afterSubmit,
    ],
  };

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="createDeliveryBox"
      headlineIcon={iconDeliveryBox}
      primary={primary}
      secondary={secondary}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Item>
          <TextField
            autoComplete="new-username"
            autoFocus
            label="labelDescription"
            name="description"
            rules={{ required: true }}
          />
          <TextField
            autoComplete="new-password"
            label="labelPassword"
            name="password"
            rules={{ required: true, minLength: 8 }}
            type="passwordWithGenerator"
          />
        </Section.Item>
      </Form>
    </DefaultModal>
  );
};

export default CreateDeliveryBox;
