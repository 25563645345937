import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox, {
  SelectBoxOptions,
} from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import TextField from "@mittwald/flow-components/dist/components/TextField";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import { iconSpinner } from "@mittwald/flow-icons/dist/spinner";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC, useState } from "react";
import {
  Container,
  CreateContainerInputs,
  ImageMeta,
  ImageMetaProps,
} from "../../../../../../model/container/Container";
import { ContainerPortList } from "../../../../../../model/container/ContainerPortList";
import { ContainerVolumeRelationList } from "../../../../../../model/container/ContainerVolumeRelationList";
import AiGenerationPlaceholder from "./AiGenerationPlaceholder";

interface Props extends ImageMetaProps {
  setImageMeta: (meta?: ImageMeta) => void;
}

export const ImageStep: FC<Props> = (props) => {
  const { imageMeta: imageMetaFromProps, setImageMeta } = props;
  const { projectId } = usePathParams("projectId");
  const form = useFormContext<CreateContainerInputs>();
  const [isValidatingImage, setIsValidatingImage] = useState<boolean>(false);
  const [isGeneratingAiSuggestions, setIsGeneratingAiSuggestions] =
    useState<boolean>(false);
  const aiGenerationAvailable =
    imageMetaFromProps?.isAiAvailable && !imageMetaFromProps.hasAiGeneratedData;

  const presets = ["solr", "mongo", "postgres", "bitnami/keycloak"];
  const selectBoxOptions: SelectBoxOptions = presets.map((p) => ({
    value: p,
    title: p,
  }));

  const imageReference = form.watch("imageReference");

  const validateImageAndSetMeta = async (
    imageRef: string,
  ): Promise<boolean> => {
    if (
      imageMetaFromProps &&
      imageMetaFromProps.imageRef === imageRef &&
      !aiGenerationAvailable
    ) {
      return true;
    }
    setIsValidatingImage(true);
    setIsGeneratingAiSuggestions(aiGenerationAvailable ?? false);
    const imageMeta = await Container.getImageMeta(
      imageRef,
      projectId,
      aiGenerationAvailable,
    );
    if (!imageMeta) {
      setIsValidatingImage(false);
      setIsGeneratingAiSuggestions(false);
      setImageMeta();
      return false;
    }
    setIsValidatingImage(false);
    setIsGeneratingAiSuggestions(false);
    setImageMeta(imageMeta);

    form.setValue(
      "volumes",
      imageMeta.volumes
        ? ContainerVolumeRelationList.fromImageMeta(
            imageMeta.volumes,
          ).asFormData()
        : [],
    );
    form.setValue(
      "ports",
      imageMeta.exposedPorts
        ? ContainerPortList.fromImageMeta(imageMeta.exposedPorts).asFormData()
        : [],
    );
    form.setValue("environments", []);
    return true;
  };

  const debouncedValidate = useAsyncDebounce(validateImageAndSetMeta, 500);

  return (
    <WizardStep
      disabledPrimaryAction={isValidatingImage || isGeneratingAiSuggestions}
      id="image"
      indicatorText="image"
    >
      {isGeneratingAiSuggestions && <AiGenerationPlaceholder />}
      {!isGeneratingAiSuggestions && (
        <Section.Item headline="selectImage">
          <Text
            i18n={{
              id: "description",
              values: {
                link: (
                  <TextLink
                    action="https://hub.docker.com/search"
                    i18n="dockerHub"
                  />
                ),
              },
            }}
          />
          <ColumnLayout medium={[2, 1]}>
            <TextField
              autoFocus
              icon={isValidatingImage ? iconSpinner : undefined}
              iconProps={{ className: "fa-spin", _alignSelf: "center" }}
              label="image"
              name="imageReference"
              placeholder="imagePlaceholder"
              rules={{
                required: true,
                validate: {
                  invalidImageRef: (val) => debouncedValidate(val),
                },
              }}
            />
          </ColumnLayout>
          <SelectBox.View
            hideCheckmarks
            options={selectBoxOptions}
            value={imageReference}
            onChange={(v) => {
              form.setValue("imageReference", v);
              void form.trigger();
            }}
          />
        </Section.Item>
      )}
    </WizardStep>
  );
};

export default ImageStep;
