import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { LinkList } from "@mittwald/flow-components/dist/components/LinkList";
import { formatPhoneNumber } from "@mittwald/flow-components/dist/components/PhoneNumber/libs";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useAnimationController } from "@mittwald/flow-components/dist/hooks/useAnimationController";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { LinkIdAction } from "@mittwald/flow-components/dist/lib/actions/LinkIdAction";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconPhone } from "@mittwald/flow-icons/dist/phone";
import { Section as NewSection } from "@mittwald/flow-react-components";
import React, { FC, useRef } from "react";
import { CodeInput } from "../../../../../../components/CodeInput";
import removeSpaces from "../../../../../../lib/removeSpaces";
import { ModelActionBuilder } from "../../../../../../model/actions/ModelActionBuilder";
import User from "../../../../../../model/user/User";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import {
  EditPhoneNumberFormFields,
  VerifyPhoneNumberFormFields,
} from "../../../types";

export const UpdateProfilePhoneNumber: FC = () => {
  const user = User.useMe();
  const phoneNumber = formatPhoneNumber(user.data.phoneNumber ?? "");
  const visibilityController = useVisibilityController();

  const enteredPhoneNumber = useRef<string>(null);
  const rejectionAnimation = useAnimationController();

  const enterForm = useForm<EditPhoneNumberFormFields>({
    showSuccessFeedback: false,
    defaultValues: {
      phoneNumber,
    },
    onSubmit: async (values) => {
      if (!values.phoneNumber) {
        throw new Error("phonenumber not set");
      }

      enteredPhoneNumber.current = values.phoneNumber;

      await user.addPhoneNumber({ phoneNumber: values.phoneNumber });
    },
  });

  const verificationForm = useForm<VerifyPhoneNumberFormFields>({
    defaultValues: {
      code: "",
    },
    translationKey: "userPhoneVerification",
    onSubmit: async (values) => {
      if (!enteredPhoneNumber.current) {
        throw new Error("Expected entered phone number not to be empty");
      }

      const verified = await user.verifyPhoneNumber(
        enteredPhoneNumber.current,
        values.code,
        rejectionAnimation,
      );

      if (verified === false) {
        verificationForm.setError("code", {
          message: "invalidMfaCode",
        });
        return false;
      }
    },
  });

  const deletePhoneNumberAction: AnyAction = [
    ModelActionBuilder.build(user, "deletePhoneNumber"),
    new LinkIdAction("profile-index"),
    visibilityController.hide,
  ];

  const enterStep = (
    <WizardIntroStep
      description="description"
      form={enterForm}
      headline="enter"
      headlineIcon={iconPhone}
      id="intro"
      indicatorText="enter"
    >
      {phoneNumber && (
        <NewSection>
          <Section.Item>
            <LinkList>
              <LinkList.Item
                action={deletePhoneNumberAction}
                destructive
                i18n="deletePhoneNumber"
              />
            </LinkList>
          </Section.Item>
        </NewSection>
      )}
      <NewSection>
        <Section.Item>
          <PhoneNumberInput
            additionalValidations={{
              hasChanged: (number: string) => {
                return (
                  removeSpaces(number) !== removeSpaces(user.data.phoneNumber)
                );
              },
            }}
            autoFocus
            name="phoneNumber"
          />
        </Section.Item>
      </NewSection>
    </WizardIntroStep>
  );

  const verifyStep = (
    <WizardStep form={verificationForm} id="verify" indicatorText="verify">
      <NewSection>
        <Section.Item headline="verify">
          {enteredPhoneNumber.current && (
            <Text
              i18n={{
                id: "description",
                values: { phone: enteredPhoneNumber.current },
              }}
            />
          )}
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          <ColumnLayoutContainer>
            <CodeInput
              label="label"
              name="code"
              rejectionAnimation={rejectionAnimation}
            />
          </ColumnLayoutContainer>
        </Section.Item>
      </NewSection>
    </WizardStep>
  );

  return (
    <WizardModal
      _size="s"
      steps={["intro", "verify"]}
      visibility={visibilityController}
    >
      {enterStep}
      {verifyStep}
    </WizardModal>
  );
};

export default UpdateProfilePhoneNumber;
