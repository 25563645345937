import UnexpectedResponseError from "@mittwald/api-client/dist/UnexpectedResponseError";
import { FileData } from "@mittwald/flow-components/dist/components/FileInputField";
import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { joinTranslationKeys } from "@mittwald/flow-components/dist/lib/translation";
import { iconFileCertificate } from "@mittwald/flow-icons/dist/fileCertificate";
import React, { FC } from "react";
import { MittwaldApi } from "../../../../../../../../api/Mittwald";
import useContextState, {
  StateReturnContext,
} from "../../../../../../../../hooks/useContextState";
import SSLCertificate from "../../../../../../../../model/domain/ssl/SSLCertificate";
import { Project } from "../../../../../../../../model/project";
import { getCertificateData } from "../../../../utils/getCertificateData";
import {
  getSSLCertificateErrorTranslationKey,
  isSSLCertificateKeyError,
} from "../../../../utils/sslCertificateApiErrors";
import { useCaStepState } from "../../../components/CaStep/CaStep";
import { KeyField } from "../../../components/KeyField/KeyField";
import { useCertificateStepState } from "../CertificateStep/CertificateStep";

interface State {
  privateKey: FileData[] | string;
  privateKeyData: string;
  sslRequestState?: MittwaldApi.Paths.V2_Certificate_Requests.Post.Responses.$201.Content.Application_Json;
}
export const usePrivateKeyState = (): StateReturnContext<State> => {
  return useContextState<State>(
    {
      privateKey: [],
      privateKeyData: "",
    },
    "privateKeyState",
  );
};

export const PrivateKeyStep: FC = () => {
  const [state, setState] = usePrivateKeyState();
  const [publicKeyState] = useCertificateStepState();
  const [caState] = useCaStepState();
  const project = Project.useLoadByPathParam();

  const form = useForm<State>({
    defaultValues: {
      privateKey: state.privateKey,
    },
    onSubmit: async (values) => {
      const privateKeyData = getCertificateData(values.privateKey);

      try {
        if (!privateKeyData.toLowerCase().includes("private")) {
          throw new Error("containsNoPrivateKey");
        }

        const certificate = publicKeyState.certificateData.concat(
          `\n${caState.caData}`,
        );

        const createOrderRequest = await SSLCertificate.createOrderRequest(
          project.id,
          certificate,
          privateKeyData,
        );

        setState({
          privateKey: values.privateKey,
          privateKeyData: privateKeyData,
          sslRequestState: createOrderRequest,
        });
      } catch (e) {
        if (e instanceof UnexpectedResponseError) {
          if (isSSLCertificateKeyError(e.errorMessage)) {
            form.setError("privateKey", {
              message: getSSLCertificateErrorTranslationKey(e.errorMessage),
            });

            return false;
          }
        }

        form.setError("privateKey", {
          message: joinTranslationKeys(
            "modal",
            "uploadCertificate",
            "privateKey",
            e instanceof Error ? e.message : "invalidPrivateKey",
          ),
        });

        return false;
      }
    },
  });

  return (
    <WizardIntroStep
      description="privateKey.description"
      form={form}
      headline="privateKey"
      headlineIcon={iconFileCertificate}
      id="privateKey"
      indicatorText="privateKeyStep"
    >
      <KeyField
        accept={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "text/plain": [".key", ".txt", ".pem"],
        }}
        name="privateKey"
        placeholder={{
          text: "-----BEGIN PRIVATE KEY-----\n...\n-----END PRIVATE KEY-----",
        }}
      />
    </WizardIntroStep>
  );
};
