import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { isDomain } from "@mittwald/flow-lib/dist/validation/domain";
import noDuplicate from "@mittwald/flow-lib/dist/validation/noDuplicate";
import React, { FC } from "react";
import { IngressList } from "../../../../../../model/domain/IngressList";
import { Section as NewSection } from "@mittwald/flow-react-components";

export const VHostStep: FC = () => {
  const { projectId } = usePathParams("projectId");
  return (
    <WizardStep id="vHost" indicatorText="vHostStep">
      <NewSection>
        <Section.Item headline="vHost">
          <Text i18n="vHostDescription" />
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          <Render>
            {() => {
              const ingresses =
                IngressList.useLoadAllWithDomainByProjectId().useItems();
              const hostnames = ingresses
                .filter((i) => i.domain || i.projectId === projectId)
                .map((i) => i.hostname);
              return (
                <TextField
                  autoFocus
                  label="hostname"
                  name="hostname"
                  rules={{
                    required: true,
                    validate: {
                      validDomain: (value: string): boolean => isDomain(value),
                      noDuplicate: noDuplicate(hostnames, {
                        convertPunycode: true,
                      }),
                    },
                  }}
                />
              );
            }}
          </Render>
        </Section.Item>
      </NewSection>
    </WizardStep>
  );
};

export default VHostStep;
