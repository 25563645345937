import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { DatabaseUserPasswordField } from "../../../components/DatabaseUserPasswordField";

export const UserStep: FC = () => {
  return (
    <WizardStep id="user" indicatorText="userStep">
      <Section.Item headline="user">
        <Text i18n="userText" />
        <ColumnLayout medium={[1, 1]}>
          <DatabaseUserPasswordField name="user.password" />
        </ColumnLayout>
      </Section.Item>
    </WizardStep>
  );
};

export default UserStep;
