import { Box } from "@mittwald/flow-components/dist/components/Box";
import { H1 } from "@mittwald/flow-components/dist/components/H1";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconSpinner } from "@mittwald/flow-icons/dist/spinner";
import React, { FC } from "react";

export const AiGenerationPlaceholder: FC = () => {
  return (
    <TranslationProvider name="placeholderBox" type="component">
      <Box
        _alignItems="center"
        _flexDirection="row"
        _justifyContent="center"
        _mt="s"
      >
        <Box _inset="s" _itemGap="m" _justifyContent="center">
          <Box _flexDirection="row" _justifyContent="center">
            <Icon
              _color="status-info-dark"
              _size="xl"
              className="fa-spin"
              icon={iconSpinner}
            />
          </Box>
          <H1 center i18n="aiGeneration" />
          <Box _itemGap="m">
            <Text center i18n="aiGenerationDescription" />
          </Box>
        </Box>
      </Box>
    </TranslationProvider>
  );
};

export default AiGenerationPlaceholder;
