import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import {
  FormController,
  useForm,
} from "@mittwald/flow-components/dist/components/Form";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { isEmail } from "@mittwald/flow-lib/dist/validation";
import React, { FC, useState } from "react";
import {
  Domain,
  HandleInputs,
  NewDomainInputs,
} from "../../../../../../model/domain/Domain";
import { DomainList } from "../../../../../../model/domain/DomainList";
import { TldList } from "../../../../../../model/domain/TldList";
import { ContactFields } from "../../../owner/components/ContactFields/ContactFields";
import { ExistingContactSelect } from "../../../owner/components/ExistingContactSelect/ExistingContactSelect";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface OwnerStepProps {
  hostname: string;
  form: FormController<NewDomainInputs>;
}

export const OwnerStep: FC<OwnerStepProps> = (props) => {
  const { form, hostname } = props;
  const [
    currentOwnerC,
    currentAdminIsOwner,
    currentDomain,
    domainCheckerUsed,
    selectedDomain,
  ] = form.watch([
    "ownerC",
    "adminIsOwner",
    "domain",
    "domainCheckerUsed",
    "selectedDomain",
  ]);

  const domain = domainCheckerUsed === "true" ? selectedDomain : currentDomain;

  const [adminIsOwner, setAdminIsOwner] = useState(currentAdminIsOwner);

  const ownerForm = useForm<HandleInputs>({
    defaultValues: {
      ownerC: currentOwnerC,
    },
    onSubmit: (values) => {
      form.setValue("ownerC", values.ownerC);
      form.setValue("adminC", values.ownerC);
      form.setValue("adminIsOwner", adminIsOwner);
    },
    shouldUnregister: true,
  });

  return (
    <WizardStep form={ownerForm} id="owner" indicatorText="ownerStep">
      <Render>
        {() => {
          const watchedFormValues = ownerForm.watch();
          const watchedOwnerEmail = ownerForm.watch("ownerC.email") as string;
          const schemas = Domain.useContactSchema(hostname);
          const domainHandleInputs = Domain.useHandleUserInputs(
            schemas,
            watchedFormValues,
          );

          const [selectedIndex, setSelectedIndex] = useState<number>(1);

          const tld =
            TldList.useGetSupportedTldsForOrder().useTldFromName(hostname);

          const existingContacts =
            DomainList.useLoadAllByProjectId().useContactRecordObjects();

          const ownerCInputs = domainHandleInputs?.ownerC.items;
          const adminCInputs = domainHandleInputs?.adminC?.items;

          return (
            <>
              <NewSection>
                <Section.Item headline="owner">
                  <Text i18n="ownerDescription" />
                </Section.Item>
              </NewSection>{" "}
              <NewSection>
                <Section.Item>
                  <ExistingContactSelect
                    contacts={existingContacts}
                    currentInputs={ownerCInputs ?? []}
                    currentSchema={schemas.ownerC}
                    form={ownerForm}
                    selectedIndex={selectedIndex}
                    onChange={(index) => setSelectedIndex(index)}
                  />
                  <ContactFields
                    fieldNamePrefix="ownerC"
                    hostname={domain}
                    userInputs={ownerCInputs}
                    onFieldValueChange={() => setSelectedIndex(1)}
                  />
                  {tld &&
                    tld.isICANN &&
                    watchedOwnerEmail &&
                    isEmail(watchedOwnerEmail) && (
                      <Banner
                        description={{
                          id: "domainIsIcann.description",
                          values: {
                            email: watchedOwnerEmail ? watchedOwnerEmail : "",
                          },
                        }}
                        headline={{
                          id: "domainIsIcann.headline",
                          values: {
                            domain: hostname,
                          },
                        }}
                        info
                      />
                    )}
                </Section.Item>
              </NewSection>
              {adminCInputs && (
                <NewSection>
                  <Section.Item>
                    <Text i18n="otherAdmin" />
                    <CheckBox.View
                      name="adminIsOwner"
                      title="otherAdmin"
                      value={!adminIsOwner}
                      onChange={(value) => setAdminIsOwner(!value)}
                    />
                  </Section.Item>
                </NewSection>
              )}
            </>
          );
        }}
      </Render>
    </WizardStep>
  );
};

export default OwnerStep;
