import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconCustomer } from "@mittwald/flow-icons/dist/customer";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC, useRef } from "react";
import {
  Customer,
  CustomerCreateInputs,
} from "../../../../../model/customer/Customer";

export const CreateCustomer: FC = () => {
  const goto = useGotoLink();
  const visibility = useVisibilityController();
  const newCustomerId = useRef<string>(null);

  const form = useForm<CustomerCreateInputs, string>({
    defaultValues: {
      name: "",
    },
    translationKey: "addCustomer",
    onSubmit: async (values) => {
      const customer = await Customer.createNew(values);
      newCustomerId.current = customer.id;
    },
  });

  const gotoNewCustomer = (): void => {
    if (newCustomerId.current) {
      goto(
        "customerDetails",
        { customerId: newCustomerId.current },
        { replace: true },
      );
    }
  };

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="createCustomer"
      headlineIcon={iconCustomer}
      primary={{
        action: [{ form }, visibility.hide, gotoNewCustomer],
        ok: true,
      }}
      visibility={visibility}
    >
      <Form controller={form}>
        <TextField
          autoFocus
          label="name"
          name="name"
          rules={{ required: true }}
        />
      </Form>
    </DefaultModal>
  );
};

export default CreateCustomer;
