import { faCirclePlay } from "@fortawesome/pro-regular-svg-icons/faCirclePlay";
import { faStopCircle } from "@fortawesome/pro-regular-svg-icons/faStopCircle";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { iconContainerRecreate } from "@mittwald/flow-icons/dist/containerRecreate";
import { iconContainerRestart } from "@mittwald/flow-icons/dist/containerRestart";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import React from "react";
import CreateContainer from "../../../pages/app/container/modals/CreateContainer/CreateContainer";
import CreateRegistry from "../../../pages/app/container/modals/CreateRegistry/CreateRegistry";
import CreateVolume from "../../../pages/app/container/modals/CreateVolume";
import DeleteVolume from "../../../pages/app/container/modals/DeleteVolume";
import { Container } from "../../container/Container";
import { Registry } from "../../container/Registry";
import { Volume } from "../../container/Volume";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";
import { Role } from "../acl/Role";

const defaultContainerRoles = [Role.projectAdmin, Role.projectDeveloper];

ModelActionBuilderRegistry.register({
  model: Container,
  roles: defaultContainerRoles,
  method: "createNew",
  altAction: () => () => showModal("createContainer", <CreateContainer />),
})
  .register({
    model: Container,
    roles: defaultContainerRoles,
    method: "delete",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteContainer",
        required: true,
        modalProps: { headlineIcon: iconDelete },
        modalTranslationValues: {
          containerName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Container,
    roles: defaultContainerRoles,
    method: "restart",
    isAvailable: (container) =>
      container.status !== "creating" && container.status !== "starting",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "restartContainer",
        required: true,
        modalProps: {
          headlineIcon: iconContainerRestart,
          primary: { ok: true, destructive: false },
        },
        modalTranslationValues: {
          containerName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Container,
    roles: defaultContainerRoles,
    method: "recreate",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "recreateContainer",
        required: true,
        modalProps: {
          headlineIcon: iconContainerRecreate,
          primary: { ok: true, destructive: false },
        },
        modalTranslationValues: {
          containerName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Container,
    roles: defaultContainerRoles,
    method: "stop",
    isAvailable: (container) => container.status !== "stopped",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "stopContainer",
        required: true,
        modalProps: { headlineIcon: faStopCircle },
        modalTranslationValues: {
          containerName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Container,
    roles: defaultContainerRoles,
    method: "start",
    isAvailable: (container) => container.status === "stopped",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "startContainer",
        required: true,
        modalProps: {
          headlineIcon: faCirclePlay,
          primary: { ok: true, destructive: false },
        },
        modalTranslationValues: {
          containerName: action.model.description,
        },
      },
    }),
  })
  .register({
    model: Volume,
    roles: defaultContainerRoles,
    method: "createNew",
    altAction: () => () => showModal("createVolume", <CreateVolume />),
  })
  .register({
    model: Volume,
    roles: defaultContainerRoles,
    method: "delete",
    altAction: (action) => () =>
      showModal("deleteVolume", <DeleteVolume volume={action.model} />),
  })
  .register({
    model: Registry,
    roles: defaultContainerRoles,
    method: "createNew",
    altAction: () => () => showModal("createRegistry", <CreateRegistry />),
  })
  .register({ model: Registry, roles: defaultContainerRoles, method: "update" })
  .register({
    model: Registry,
    roles: defaultContainerRoles,
    method: "delete",
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteRegistry",
        required: true,
        modalProps: { headlineIcon: iconDelete },
        modalTranslationValues: {
          registryName: action.model.description,
        },
      },
    }),
  });
