import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import {
  isDomain,
  isSubdomain,
} from "@mittwald/flow-lib/dist/validation/domain";
import noDuplicate from "@mittwald/flow-lib/dist/validation/noDuplicate";
import { Section as NewSection } from "@mittwald/flow-react-components";
import React, { FC, useDeferredValue } from "react";
import { IngressList } from "../../../../../../model/domain/IngressList";
import { IngressListUI } from "../../../../../../model/ui/domain/IngressListUI";

export const SubdomainStep: FC = () => {
  const { projectId } = usePathParams("projectId");
  const accessibleIngressList =
    IngressList.useLoadAllAccessible().useEnabledIngressList();
  const ingresses = accessibleIngressList.useItems();

  const ingressListUI = IngressListUI.of(accessibleIngressList);

  const hostnames = ingresses.map((i) => i.hostname);
  const getSuggestion = useDeferredValue(
    ingressListUI.useSubdomainSuggestions(projectId),
  );

  return (
    <>
      <NewSection>
        <Section.Item headline="subdomain">
          <Text i18n="subdomainDescription" />
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          <AutoCompleteField
            label="subdomain"
            name="subdomain"
            rules={{
              required: true,
              validate: {
                noDuplicate: (subdomain: string) =>
                  noDuplicate(hostnames, {
                    convertPunycode: true,
                  })(subdomain),
                isDkimSubdomain: (subdomain: string) =>
                  !subdomain.includes("_"),
                noSubdomain: (subdomain: string) => {
                  if (!subdomain || subdomain.length === 0) {
                    return true;
                  }
                  if (!isDomain(subdomain)) {
                    return false;
                  }
                  const isSubdomainParse = isSubdomain(subdomain);
                  return typeof isSubdomainParse === "boolean"
                    ? isSubdomainParse
                    : false;
                },
              },
            }}
            suggestions={getSuggestion}
          />
        </Section.Item>
      </NewSection>
    </>
  );
};

export default SubdomainStep;
