import Form, { useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import React, { FC } from "react";
import Customer from "../../../../../model/customer/Customer";
import SpaceServerProject from "../../../../../model/project/SpaceServerProject";
import Server from "../../../../../model/server/Server";
import ArticleTypeProjectDetailsSection from "./components/ArticleTypeProjectDetailsSection";

interface ServerProjectFormFields {
  description: string;
  serverId: string;
}

interface Props {
  server?: Server;
  customer?: Customer;
}

export const CreateServerProject: FC<Props> = (props) => {
  const modalVisibility = useVisibilityController();

  const createProjectForm = useForm<ServerProjectFormFields>({
    defaultValues: {
      description: "",
      serverId: props.server?.id ?? "",
    },
    onSubmit: async (values) => {
      await SpaceServerProject.createNew(values);
    },
  });

  return (
    <DefaultModal
      description="description"
      headline="createProject"
      headlineIcon={iconProject}
      primary={{
        action: [{ form: createProjectForm }, modalVisibility.hide],
        ok: true,
      }}
      visibility={modalVisibility}
    >
      <Form controller={createProjectForm}>
        <ArticleTypeProjectDetailsSection
          customer={props.customer}
          server={props.server}
          spaceServerSelected
        />
      </Form>
    </DefaultModal>
  );
};
