import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import ExtensionInstance from "../../marketplace/ExtensionInstance";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

ModelActionBuilderRegistry.register({
  model: ExtensionInstance,
  method: "deleteForCustomer",
  roles: [Role.customerOwner, Role.customerMember],
  altAction: (action) => ({
    action,
    confirmation: {
      modalTranslationKey: "deleteExtensionInstance",
      required: true,
      modalTranslationValues: {
        extension: action.model.useExtension().data.name,
      },
      modalProps: { headlineIcon: iconDelete },
    },
  }),
})
  .register({
    model: ExtensionInstance,
    method: "deleteForProject",
    roles: [Role.projectAdmin],
    altAction: (action) => ({
      action,
      confirmation: {
        modalTranslationKey: "deleteExtensionInstance",
        required: true,
        modalTranslationValues: {
          extension: action.model.useExtension().data.name,
        },
        modalProps: { headlineIcon: iconDelete },
      },
    }),
  })

  .register({
    model: ExtensionInstance,
    method: "enable",
    roles: [Role.anybody],
  })
  .register({
    model: ExtensionInstance,
    method: "disable",
    roles: [Role.anybody],
  });
