import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import User from "../model/user/User";
import userSettingsStore from "../store/userSettings";

export const useUpdateRecentVisits = (projectId: string): void => {
  const { pathname } = useLocation();
  const myUserId = User.useMe().id;

  useEffect(() => {
    userSettingsStore.recentVisits.recordVisit(
      projectId,
      "project",
      pathname,
      myUserId,
    );
  }, [projectId, pathname]);
};

export default useUpdateRecentVisits;
