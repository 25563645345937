import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import { SpaceServerArticle } from "../../../../../../model/article";
import { MachineTypeSection } from "./MachineTypeSection";
import OrderServerPricePreviewBox from "./OrderServerPricePreviewBox";
import { StorageSection } from "./StorageSection";
import { Section as NewSection } from "@mittwald/flow-react-components";

export interface ServerStepContentProps {
  article: SpaceServerArticle;
  freeTrial: boolean;
}

export const ServerStep: FC<ServerStepContentProps> = (props) => {
  const { article, freeTrial } = props;

  return (
    <WizardStep
      id="server"
      indicatorText="serverStep"
      infoBoxContent={
        <Suspense loadingView={<LoadingView _height={250} />}>
          <OrderServerPricePreviewBox article={article} freeTrial={freeTrial} />
        </Suspense>
      }
    >
      <Suspense loadingView={<LoadingView />}>
        <NewSection>
          <Section.Item headline="server">
            <TextField
              autoFocus
              label="description"
              name="description"
              rules={{ required: true }}
            />
          </Section.Item>
        </NewSection>
        <NewSection>
          <MachineTypeSection />
        </NewSection>
        <NewSection>
          <StorageSection article={article} />
        </NewSection>
      </Suspense>
    </WizardStep>
  );
};
