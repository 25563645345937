import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import Form, { useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDesktop } from "@mittwald/flow-icons/dist/desktop";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import useOptionalPathParams from "@mittwald/flow-lib/dist/hooks/useOptionalPathParams";
import React, { FC } from "react";
import { UserInputField } from "../../../../../components/UserInputField";
import App from "../../../../../model/app/App";
import AppInstallation from "../../../../../model/app/AppInstallation";
import AppUserInput from "../../../../../model/app/AppUserInput";
import { UserInputsRecordObject } from "../../../../../model/misc/userInput/UserInputRecordList";
import AppVersionSelect from "../../components/AppVersionSelect";
import SystemSoftwareDependencySelect from "./components/SystemSoftwareDependencySelect";

interface Props {
  appInstallation: AppInstallation;
}

export const UpdateAppInstallationVersion: FC<Props> = (props) => {
  const { appInstallation } = props;
  const visibility = useVisibilityController();
  const app = appInstallation.useApp();
  const laterVersions = appInstallation.useLaterVersions();
  const latestVersion = laterVersions.latest();
  const currentVersion = appInstallation.useVersion();
  const pathParams = useOptionalPathParams();

  const goto = useGotoLink();

  const form = useForm<{
    appVersionId: string;
    userInputs?: UserInputsRecordObject;
    systemSoftwares: { [key: string]: string };
  }>({
    defaultValues: { appVersionId: latestVersion.data.id },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const systemSoftwareInputs = values.systemSoftwares
        ? Object.keys(values.systemSoftwares).reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                systemSoftwareVersion: values.systemSoftwares[key],
                updatePolicy: "patchLevel",
              },
            }),
            {},
          )
        : undefined;

      await appInstallation.updateVersion({
        appVersionId: values.appVersionId,
        userInputs: values.userInputs,
        systemSoftware: systemSoftwareInputs,
      });

      visibility.hide();
      goto(pathParams.projectId ? "apps" : "globalApps");
    },
  });

  const watchedAppVersionId = form.watch("appVersionId");

  const appVersions = watchedAppVersionId
    ? app.useVersions(true)
    : App.useEmptyVersions();
  const appVersion = watchedAppVersionId
    ? appVersions.findById(watchedAppVersionId)
    : undefined;

  const missingDependencies =
    appInstallation.useMissingDependencies(watchedAppVersionId);
  const missingUserInputsExist =
    missingDependencies?.missingUserInputs &&
    missingDependencies.missingUserInputs.length > 0;

  const missingSystemSoftwareDependenciesExist =
    missingDependencies?.missingSystemSoftwareDependencies &&
    missingDependencies.missingSystemSoftwareDependencies.length > 0;

  const appVersionSelectSection = (
    <Section.Item headline={{ id: "version", values: { app: app.name } }}>
      <StaticInformation
        label="currentVersion"
        text={{ text: currentVersion.data.externalVersion }}
      />
      <ColumnLayout medium={[1, 1]}>
        <AppVersionSelect name="appVersionId" versions={laterVersions.items} />
      </ColumnLayout>
    </Section.Item>
  );

  if (!missingUserInputsExist && !missingSystemSoftwareDependenciesExist) {
    return (
      <DefaultModal
        _size="s"
        description="description"
        headline="updateAppInstallationVersion"
        headlineIcon={iconDesktop}
        primary={{
          action: { form },
          ok: true,
        }}
        visibility={visibility}
      >
        <Form controller={form}>{appVersionSelectSection}</Form>
      </DefaultModal>
    );
  }

  return (
    <WizardModal
      _size="s"
      form={form}
      steps={[
        "update",
        "missingUserInputs",
        "missingSystemSoftwareDependencies",
      ]}
    >
      <WizardIntroStep
        description="description"
        headline="updateAppInstallationVersion"
        headlineIcon={iconDesktop}
        id="update"
        indicatorText="updateStep"
      >
        {appVersionSelectSection}
      </WizardIntroStep>
      {appVersion &&
        missingDependencies.missingUserInputs &&
        missingUserInputsExist && (
          <WizardStep
            id="missingUserInputs"
            indicatorText="missingUserInputsStep"
          >
            {missingDependencies.missingUserInputs.map((userInput) => (
              <ColumnLayout key={userInput.name}>
                <UserInputField
                  fieldNamePrefix="userInputs"
                  userInput={AppUserInput.fromApiData(appVersion, userInput)}
                />
              </ColumnLayout>
            ))}
          </WizardStep>
        )}
      {missingDependencies.missingSystemSoftwareDependencies &&
        missingSystemSoftwareDependenciesExist && (
          <WizardStep
            id="missingSystemSoftwareDependencies"
            indicatorText="missingSystemSoftwareDependenciesStep"
          >
            <Section.Item>
              {missingDependencies.missingSystemSoftwareDependencies.map(
                (dependency) => (
                  <SystemSoftwareDependencySelect
                    dependency={dependency}
                    key={dependency.systemSoftwareId}
                  />
                ),
              )}
            </Section.Item>
          </WizardStep>
        )}
    </WizardModal>
  );
};

export default UpdateAppInstallationVersion;
