import { useRemountOnChange } from "@mittwald/flow-components/dist/components/RemountBoundary/hooks/useRemountOnChange";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React from "react";
import DnsZone, { DnsRecordApiData, RecordSettings } from "../../dns/DnsZone";
import Ingress from "../../domain/Ingress";

export const RecordTypes = {
  A: "A",
  CNAME: "CNAME",
  MX: "MX",
  TXT: "TXT",
  SRV: "SRV",
  CAA: "CAA",
};

export interface DnsResourceListItem {
  recordType: string;
  managed: boolean;
  recordCount: number;
}

export type Ttl = "auto" | number;

export const availableTTLs: Ttl[] = [
  "auto",
  300,
  900,
  1800,
  3600,
  10800,
  21600,
  43200,
  86400,
];

export class DnsZoneUI {
  public static readonly defaultTxtRecord =
    "v=spf1 include:agenturserver.de ~all";
  public static readonly dkim =
    "v=DKIM1; k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC3U8kqa4SiUlO5WBe8DwVPhhLNooNNe8VLEJ+9PbDHVeEw0O6mY2O6AvcqbnSeBO5Ac9Ix0RQzxe9krqSgWDR84IvROW/u4kMxELn+Q+Jy2QXYASbVWnYs4T6p1yIqBEgRfWDFnNtmRDvNFCAcRv2VkA0ykkRMq3u9E6FZTLMnGQIDAQAB";

  public static readonly availableTTLOptions: SelectOptions = availableTTLs.map(
    (value) => {
      if (value === "auto") {
        return {
          label: "auto",
          value: "auto",
        };
      } else {
        return { label: DnsZoneUI.getReadableTimeFromSeconds(value), value };
      }
    },
  );

  public static getReadableTimeFromSeconds(seconds: number): I18nDefinition {
    const hours = Math.floor(seconds / 3600);
    const hoursRest = seconds % 3600;
    const minutes = hoursRest / 60;

    if (hours === 0) {
      return (
        <Text
          i18n={{ id: "readableTimeMinutes", values: { minutes, seconds } }}
        />
      );
    } else if (minutes === 0) {
      return (
        <Text i18n={{ id: "readableTimeHours", values: { hours, seconds } }} />
      );
    } else {
      return (
        <Text
          i18n={{
            id: "readableTimeMinutesAndHours",
            values: { minutes, hours, seconds },
          }}
        />
      );
    }
  }

  public static useDnsResourceListItems(
    ingress: Ingress,
  ): DnsResourceListItem[] {
    const zone = DnsZone.useLoadByIngress(ingress);

    const data = [
      {
        managed: zone.aRecord.isManaged,
        recordType: RecordTypes.A,
        recordCount: zone.aRecord.records.length,
      },
      {
        managed: zone.mxRecord.isManaged,
        recordType: RecordTypes.MX,
        recordCount: zone.mxRecord.records.length,
      },
      {
        managed: false,
        recordType: RecordTypes.TXT,
        recordCount: zone.txtRecordsList.recordCount,
      },
      {
        managed: false,
        recordType: RecordTypes.SRV,
        recordCount: zone.srvRecordsList.recordCount,
      },
      {
        managed: false,
        recordType: RecordTypes.CNAME,
        recordCount: zone.cnameRecordsList.recordCount,
      },
      {
        managed: false,
        recordType: RecordTypes.CAA,
        recordCount: zone.caaRecordsList.recordCount,
      },
    ];

    return useRemountOnChange(data);
  }

  public static useRecordDetailsLink(recordType: string): string {
    const buildLink = useLinkBuilder();
    return buildLink(`${recordType.toLowerCase()}Record`);
  }

  public static ttlFromRecord(record: DnsRecordApiData): Ttl {
    return "settings" in record &&
      record.settings.ttl &&
      "seconds" in record.settings.ttl
      ? record.settings.ttl.seconds
      : "auto";
  }

  public static ttlToSeconds(ttl?: Ttl): number {
    if (!ttl || ttl === "auto") {
      return 60;
    }

    return ttl;
  }

  public static ttlToRecordSettings(ttl: Ttl): RecordSettings {
    return {
      ttl: ttl === "auto" ? { auto: true } : { seconds: ttl },
    };
  }
}
export default DnsZoneUI;
