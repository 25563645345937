import { Select } from "@mittwald/flow-components/dist/components/Select";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { ProjectList } from "../../../../../model/project";

interface Props {
  name?: string;
  optional?: boolean;
}

export const ConversationProjectSelect: FC<Props> = (props) => {
  const { name = "project", optional } = props;
  const options =
    ProjectList.useConversationRelatableProjects().getSelectOptions(optional);
  return (
    <TranslationProvider name="projectSelect" type="component">
      <Select
        isSearchable
        label="project"
        name={name}
        options={options}
        placeholder="project"
        rules={{ required: !optional }}
      />
    </TranslationProvider>
  );
};
