import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import AutoCompleteField from "@mittwald/flow-components/dist/components/AutoCompleteField/AutoCompleteField";
import {
  OnBeforeSuggestionSelectedHandler,
  Suggestions,
  SuggestionsFactory,
} from "@mittwald/flow-components/dist/components/AutoCompleteField/types";
import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useAsyncDebounce } from "@mittwald/flow-components/dist/hooks/useAsyncDebounce";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconIngress } from "@mittwald/flow-icons/dist/ingress";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconSubdomain } from "@mittwald/flow-icons/dist/subdomain";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import React, { FC } from "react";
import Ingress from "../../model/domain/Ingress";
import { IngressList } from "../../model/domain/IngressList";
import { AnyProject, Project, ProjectMembership } from "../../model/project";
import User from "../../model/user/User";
import { DefaultGlassModal } from "./DefaultGlassModal";

const sortByName = (
  a: AnyProject | Ingress,
  b: AnyProject | Ingress,
): number => {
  if (a.hostname < b.hostname) {
    return -1;
  }
  if (a.hostname > b.hostname) {
    return 1;
  }
  return 0;
};

export const SearchBarModal: FC = () => {
  const visibility = useVisibilityController(false);
  const goTo = useGotoLink();

  const user = User.useMe();
  const projects = Project.useLoadByUserId(user.id);
  const ingresses = IngressList.useLoadAllAccessibleWithDomains().useItems();

  const getSuggestions: SuggestionsFactory<unknown> = (value) => {
    const cleanedSearchValue = value.replace(/\s+/, "").toLowerCase();

    if (value.length <= 0) {
      return [];
    }

    const projectArray: Suggestions<unknown> = projects
      .filter((p) => {
        return (
          p.description
            .replace(/\s+/, "")
            .toLowerCase()
            .includes(cleanedSearchValue) ||
          p.shortId.toLowerCase().trim() === value.toLowerCase().trim()
        );
      })
      .sort(sortByName)
      .map((p) => ({
        value: p.id,
        groupI18n: "projects",
        label: {
          text: (
            <TextWithIconLayout
              icon={iconProject}
              iconPosition="left"
              iconProps={{ _pl: "xxs" }}
            >
              <Text i18n={{ text: p.description }} />
            </TextWithIconLayout>
          ),
        },
        payload: p,
      }));

    const ingressArray: Suggestions<unknown> = ingresses
      .filter((i) => i.hostname.toLowerCase().includes(cleanedSearchValue))
      .sort(sortByName)
      .map((i) => {
        const icon = i.domain
          ? iconDomain
          : i.isDefault
            ? faHome
            : i.isSubdomain
              ? iconSubdomain
              : iconIngress;
        return {
          value: i.id,
          groupI18n: "domains",
          label: {
            text: (
              <TextWithIconLayout
                icon={icon}
                iconPosition="left"
                iconProps={{ _pl: "xxs" }}
              >
                <Text i18n={{ text: i.hostname }} />
              </TextWithIconLayout>
            ),
          },
          payload: i,
        };
      });

    return projectArray.concat(ingressArray);
  };

  const getSuggestionsDebounced = useAsyncDebounce(getSuggestions, 250);

  const onSuggestionSelected: OnBeforeSuggestionSelectedHandler<any> = (
    option,
  ) => {
    visibility.hide();

    let projectId: string;
    let optionsName: string;
    let optionType: "project" | "domain" | undefined = undefined;

    if ("description" in option.payload) {
      projectId = option.payload.id;
      optionsName = option.payload.description;
      optionType = "project";
    } else {
      projectId = option.payload.projectId;
      optionsName = option.payload.hostname;
      optionType = "domain";
    }

    void ProjectMembership.loadOwn(projectId).then((r) => {
      if (optionType === "domain") {
        if (r.role.is("emailadmin")) {
          goTo("mailAddresses", { projectId });
        } else {
          goTo("domainOverview", {
            projectId,
          });
        }
      } else {
        if (r.role.is("emailadmin")) {
          goTo("project", { projectId });
        } else {
          goTo("projectDashboard", { projectId });
        }
      }
    });

    return optionsName;
  };

  return (
    <TranslationProvider name="searchBar" type="modal">
      <DefaultGlassModal
        _size="s"
        closeOnClickOut
        closeOnEsc
        visibility={visibility}
      >
        <ColumnLayoutContainer>
          <AutoCompleteField.View
            allowSelectFirstSuggestionOnEnter
            autoFocus
            clear
            placeholder="searchProjectsAndDomains"
            suggestions={getSuggestionsDebounced}
            type="text"
            onBeforeSuggestionSelected={onSuggestionSelected}
          />
        </ColumnLayoutContainer>
      </DefaultGlassModal>
    </TranslationProvider>
  );
};
