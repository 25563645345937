import { Box } from "@mittwald/flow-components/dist/components/Box";
import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconPlus } from "@mittwald/flow-icons/dist/plus";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ContainerImageConfigVolume } from "../../../../../model/container/Container";
import { VolumeList } from "../../../../../model/container/VolumeList";
import { AiGradientText } from "../AiGradientText/AiGradientText";
import HeavyListPlaceholder from "../HeavyListPlaceholder";
import { VolumeHeavyListEntry } from "./VolumeHeavyListEntry";

interface Props {
  suggestedPaths?: ContainerImageConfigVolume[];
}

export const VolumeHeavyList: FC<Props> = (props) => {
  const form = useFormContext();
  const { suggestedPaths = [] } = props;
  const { projectId } = usePathParams("projectId");
  const volumeOptions =
    VolumeList.useLoadAllByProjectId(projectId).getSelectOptions();
  const { fields, append, remove } = useFieldArray({
    name: "volumes",
    control: form.control,
  });
  const [aiGeneratedStates, setAiGeneratedStates] = useState<boolean[]>([]);
  const hasAiGeneratedData = aiGeneratedStates.some((s) => s);

  const appendItem = (): void => {
    append({
      type: "volume",
      volume: "createNewVolume",
      containerPath: "/",
      projectDirectory: "/",
    });
  };

  return (
    <TranslationProvider name="volumeHeavyList" type="component">
      {fields.length > 0 && (
        <Box
          _flexDirection="row"
          _justifyContent={hasAiGeneratedData ? "space-between" : "flex-end"}
        >
          {hasAiGeneratedData && <AiGradientText i18n="aiAssisted" withIcon />}
          <TextWithIconLayout icon={iconPlus}>
            <TextLink action={appendItem} i18n="addVolume" />
          </TextWithIconLayout>
        </Box>
      )}
      {fields.length === 0 && (
        <HeavyListPlaceholder
          action={appendItem}
          actionTitle="addVolume"
          description="noVolumesDescription"
          headline="noVolumes"
        />
      )}
      {fields.map((s, index) => (
        <VolumeHeavyListEntry
          index={index}
          key={s.id}
          remove={remove}
          setAiGeneratedStates={setAiGeneratedStates}
          suggestedPaths={suggestedPaths}
          volumeOptions={volumeOptions}
        />
      ))}
    </TranslationProvider>
  );
};
