import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import React, { FC } from "react";
import { ScheduleInputs } from "../../../../../../components/ScheduleInputs/ScheduleInputs";
import { NewCronjobInputs } from "../../../../../../model/cronjob/Cronjob";
import { CronjobUI } from "../../../../../../model/ui/cronjob/CronjobUI";
import { Section as NewSection } from "@mittwald/flow-react-components";

export const IntervalStep: FC = () => {
  const form = useFormContext<NewCronjobInputs>();

  return (
    <WizardStep id="interval" indicatorText="interval">
      <NewSection>
        <Section.Item headline="interval">
          <Text i18n="interval" />
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          <ScheduleInputs
            crontabFormFieldName="interval"
            form={form}
            intervalFormFieldName="intervalSelect"
            intervals={CronjobUI.cronjobIntervals}
          />
        </Section.Item>
      </NewSection>
    </WizardStep>
  );
};

export default IntervalStep;
