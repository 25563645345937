import React, { FC } from "react";
import ModelRelationType from "../../../../../model/misc/modelRelation/ModelRelationType";
import { CustomerSelect } from "../../../customer/components/CustomerSelect";
import { ServerSelect } from "../../../server/components/ServerSelect";
import { ConversationProjectSelect } from "./ConversationProjectSelect";

export interface ConversationRelationSelectProps {
  relationType: ModelRelationType;
}

export const ConversationRelationSelect: FC<ConversationRelationSelectProps> = (
  props,
) => {
  const { relationType } = props;

  if (relationType.aggregate === "project") {
    return <ConversationProjectSelect name="relatedTo.id" />;
  }
  if (relationType.aggregate === "placementgroup") {
    return <ServerSelect name="relatedTo.id" />;
  }
  if (relationType.aggregate === "customer") {
    return <CustomerSelect name="relatedTo.id" />;
  }

  return null;
};

export default ConversationRelationSelect;
