import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { iconContainer } from "@mittwald/flow-icons/dist/container";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import React, { FC } from "react";
import {
  Container,
  serviceNameRegex,
} from "../../../../../../model/container/Container";
import { ContainerList } from "../../../../../../model/container/ContainerList";

export const GeneralStep: FC = () => {
  const { projectId } = usePathParams("projectId");
  const containers = ContainerList.useLoadAllByProjectId(projectId);
  return (
    <WizardIntroStep
      description="description"
      headline="createContainer"
      headlineIcon={iconContainer}
      id="general"
      indicatorText="details"
    >
      <Section.Item>
        <ColumnLayout medium={[1, 1]}>
          <TextField
            autoFocus
            label="description"
            name="description"
            rules={{
              required: true,
              validate: {
                duplicateServiceName: (value) =>
                  containers.checkIfServiceNameIsUnique(
                    Container.createServiceName(value),
                  ),
                validServiceName: (value) =>
                  serviceNameRegex.test(Container.createServiceName(value)),
              },
            }}
          />
        </ColumnLayout>
      </Section.Item>
    </WizardIntroStep>
  );
};

export default GeneralStep;
