import { MittwaldApi } from "../../api/Mittwald";

export type CaaRecordComponentApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCaaComponent;
export type CaaRecordSetApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCaa;

export type CaaRecordApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Dns_RecordCaaRecord;

export class CaaRecordSet {
  public readonly data: CaaRecordApiData;
  public readonly tag: "issue" | "issuewild" | "iodef";
  public readonly flags: number | undefined;
  public readonly value: string;
  public constructor(data: CaaRecordApiData) {
    this.data = Object.freeze(data);
    this.tag = data.tag;
    this.flags = data.flags;
    this.value = data.value;
  }

  public static fromApiData(data: CaaRecordApiData): CaaRecordSet {
    return new CaaRecordSet(data);
  }
}

export default CaaRecordSet;
