export type UseFileUrlBuilder = () => (fileId?: string) => string;

const makeUseFileServiceUrlBuilder = (): UseFileUrlBuilder => () => {
  // Hooks must be called once during builder creation

  return (fileId?: string): string => {
    if (!fileId) {
      return "";
    }
    if (fileId.startsWith("/") || fileId.startsWith("http")) {
      return fileId;
    } else {
      return `${import.meta.env.VITE_APP_API_URI}/v2/files/${fileId}`;
    }
  };
};

/**
 * Get the URL of a pdf file, that has been uploaded to the FileService.
 */
export const useFileUrlBuilder: UseFileUrlBuilder =
  makeUseFileServiceUrlBuilder();

/**
 * Get the URL of an image file, that has been uploaded to the FileService.
 */
export const useImageUrlBuilder: UseFileUrlBuilder =
  makeUseFileServiceUrlBuilder();
