import ImageUpload from "@mittwald/flow-components/dist/components/ImageUpload";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useAction } from "@mittwald/flow-components/dist/lib/actions/actionFactory";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";
import { iconAvatar } from "@mittwald/flow-icons/dist/avatar";
import invariant from "invariant";
import React, { FC } from "react";
import { useAvatarUpload } from "../../../../../../hooks/useAvatarUpload";
import User from "../../../../../../model/user/User";

export const UpdateProfileAvatar: FC = () => {
  const visibility = useVisibilityController();
  const user = User.useMe();

  const {
    imageUploadController,
    fileUploadController,
    maxSizeInMb,
    acceptedFilesString,
  } = useAvatarUpload(() => user.requestAvatarUpload());

  const hasAvatar = !!user.data.avatarRef;

  const uploadStatus = fileUploadController.files[0]?.status;

  const deleteAction: AnyAction = {
    actions: [() => user.removeAvatar(), visibility.hide],
    confirmation: {
      required: true,
      modalProps: { headlineIcon: iconDelete },
      modalTranslationKey: "deleteAvatar",
    },
  };

  const uploadAction = useAction([
    async () => {
      const file = await imageUploadController.buildFile();
      const fileUpload = fileUploadController.upload(file)[0];
      invariant(!!fileUpload, "Upload failed");

      await fileUpload.getRequestPromise();
    },
    visibility.hide,
  ]);

  const uploadActionIsBusy = uploadAction.watch("isBusy");

  const isActive =
    uploadActionIsBusy || imageUploadController.imageIsProcessing;

  return (
    <DefaultModal
      description={{
        id: "description",
        values: { maxSize: maxSizeInMb, accept: acceptedFilesString },
      }}
      headline="updateProfileAvatar"
      headlineIcon={iconAvatar}
      linkAction={
        hasAvatar
          ? {
              text: "deleteAvatar",
              destructive: true,
              action: deleteAction,
            }
          : undefined
      }
      primary={{
        text: "saveAvatar",
        disabled:
          !imageUploadController.image || uploadStatus?.state === "done",
        ok: true,
        action: uploadAction,
      }}
      visibility={visibility}
    >
      <ImageUpload
        _height={350}
        cropShape="round"
        disabled={isActive}
        uploadController={imageUploadController}
      />
    </DefaultModal>
  );
};

export default UpdateProfileAvatar;
