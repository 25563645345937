import { FormController } from "@mittwald/flow-components/dist/components/Form";
import Select from "@mittwald/flow-components/dist/components/Select";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { Schema } from "jsonschema";
import React, { FC } from "react";
import { Domain, HandleInputs } from "../../../../../../model/domain/Domain";
import { DomainContactOption } from "../../../../../../model/domain/DomainList";
import UserInput from "../../../../../../model/misc/userInput/UserInput";

export interface Props {
  contacts: DomainContactOption[];
  currentInputs: UserInput[];
  currentSchema: Schema;
  selectedIndex: number;
  onChange: (index: number) => void;
  form: FormController<HandleInputs>;
}

export const ExistingContactSelect: FC<Props> = (props) => {
  const {
    contacts,
    currentInputs,
    selectedIndex,
    onChange,
    form,
    currentSchema,
  } = props;

  const resetInputs = (): void => {
    currentInputs.forEach((i) =>
      form.setValue(`ownerC.${i.name}`, i.name === "type" ? "ORG" : ""),
    );
  };

  const handleChange = (index: number): void => {
    const arrayIndex = index - 2;
    onChange(index);

    resetInputs();
    const contact = index > 1 ? contacts[arrayIndex]?.contact : undefined;
    if (!contact) {
      return;
    }
    if (contact["type"] === undefined) {
      contact["type"] = "PERSON";
    }
    const inputs = Domain.getHandleInputs(currentSchema, contact).items;

    const contactFields = Object.entries(contact).filter(([key]) =>
      inputs.find((i) => i.name === key),
    );

    contactFields.forEach(([key, value]) =>
      form.setValue(`ownerC.${key}`, value),
    );
  };

  const contactOptions: SelectOptions = contacts.map((i, index) => ({
    value: index + 2,
    label: { text: `${i.domain} – ${i.contact.name} (${i.contact.email})` },
  }));

  const options: SelectOptions = [
    { value: 1, label: "noPreset" },
    ...contactOptions,
  ];

  return (
    <Select.View
      isSearchable
      label="contactPreset"
      name="contact"
      options={options}
      placeholder="contactPresetPlaceholder"
      value={selectedIndex}
      onChange={handleChange}
    />
  );
};
