import OrderResourceSelect from "@mittwald/flow-components/dist/components/OrderResourceSelect/OrderResourceSelect";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import React, { FC } from "react";
import { ProSpaceArticle } from "../../../../../../model/article";
import ProSpaceArticleUI from "../../../../../../model/ui/article/ProSpaceArticleUI";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface Props {
  currentArticle?: ProSpaceArticle;
  projectId?: string;
  isDisabled?: boolean;
}

export const ConfigurationArticleSection: FC<Props> = (props) => {
  const { currentArticle, projectId, isDisabled } = props;

  return (
    <NewSection>
      <Section.Item headline="article">
        <OrderResourceSelect
          breakpoint={300}
          minOptionWidth={120}
          name="articleFilter"
          options={ProSpaceArticleUI.useArticleFilterSelectBoxOptions(
            currentArticle,
            projectId,
            isDisabled,
          )}
        />
      </Section.Item>
    </NewSection>
  );
};

export default ConfigurationArticleSection;
