import { arrayAccessor } from "@mittwald/awesome-node-utils/misc/ArrayAccessor";
import {
  AutoCompleteFieldProps,
  OnBeforeSuggestionSelectedHandler,
  SuggestionsFactory,
} from "@mittwald/flow-components/dist/components/AutoCompleteField/types";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { iconFile } from "@mittwald/flow-icons/dist/file";
import { iconFolder } from "@mittwald/flow-icons/dist/folder";
import { iconLink } from "@mittwald/flow-icons/dist/link";
import React from "react";
import { MittwaldApi, mittwaldApi } from "../api/Mittwald";
import { appendRootPath } from "../lib/appendRootPath";
import { stripRootPath } from "../lib/stripRootPath";

type DirectoryListing =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Project_FilesystemDirectoryListing;

export const useProjectDirectoryListingCompletion = (
  projectId: string,
  directoriesOnly: boolean = false,
  directoriesAndSymlinksOnly: boolean = false,
  rootDirectory: string = "/",
  appDirectory: string = "",
): Pick<
  AutoCompleteFieldProps,
  "suggestions" | "onBeforeSuggestionSelected"
> => {
  const rootPath = rootDirectory + appDirectory;
  const getSuggestions: SuggestionsFactory<DirectoryListing> = async (
    directory: string,
  ) => {
    const result = await mittwaldApi.projectFileSystemGetDirectories.request({
      path: {
        projectId,
      },
      query: {
        directory: appendRootPath(directory, rootPath),
      },
    });

    if (result.status !== 200) {
      return [];
    }

    const currentPath = directory.split("/").filter((d) => !!d);

    const back =
      currentPath.length >= 1
        ? ({
            absolutePath: appendRootPath(
              "/" +
                currentPath
                  .slice(0, directory.endsWith("/") ? -1 : -2)
                  .join("/"),
              rootPath,
            ).replace("//", "/"),
            name: "..",
            isDirectory: true,
            isFile: false,
            isExecutable: false,
            isSymlink: false,
          } as MittwaldApi.Components.Schemas.De_Mittwald_V1_Project_FilesystemDirectoryListing)
        : undefined;

    let items =
      result.content.items && result.content.items.length > 0
        ? [...result.content.items]
        : [];

    if (directoriesOnly) {
      items = items.filter((item) => !item.isFile);
    }

    if (directoriesAndSymlinksOnly) {
      items = items.filter((item) => !item.isFile || item.isSymlink);
    }

    if (back) {
      items.push(back);
    }

    return items
      .sort((a, b) => {
        if (a.name === "..") {
          return -1;
        }

        if (a.isDirectory === b.isDirectory) {
          return a.absolutePath.localeCompare(b.absolutePath);
        }

        return a.isDirectory === b.isDirectory ? 0 : a.isDirectory ? -1 : 1;
      })
      .map((dir) => {
        const icon = dir.isSymlink
          ? iconLink
          : dir.isDirectory
            ? iconFolder
            : iconFile;

        if (
          (dir.isDirectory || dir.isSymlink) &&
          !dir.absolutePath.endsWith("/")
        ) {
          dir.absolutePath = dir.absolutePath.concat("/");
        }

        return {
          value: stripRootPath(dir.absolutePath, rootPath),
          label: (
            <Box
              _alignItems="center"
              _flexDirection="row"
              _justifyContent="flex-start"
            >
              <Icon _ml="s" icon={icon} />
              <Text _ml="s">
                {dir.name}
                {/** TODO wait for target be available on the api**/}
                {dir.isSymlink && " -> ".concat(dir.target || "")}
              </Text>
            </Box>
          ),
          payload: dir,
        };
      });
  };

  const directorySuggestions: SuggestionsFactory<DirectoryListing> = async (
    value,
  ) => {
    if (value.endsWith("/")) {
      return getSuggestions(value);
    }
    const directoryParts = value.split("/");
    const directory = directoryParts
      .filter((value, index, items) => index !== items.length - 1)
      .join("/");

    const suggestions = await getSuggestions(directory);

    const lastDirectoryPart = arrayAccessor(directoryParts).optionalLast;

    if (lastDirectoryPart) {
      return suggestions;
    }

    return [];
  };

  const directoryOptionHandler: OnBeforeSuggestionSelectedHandler = (
    option,
    currentValue,
  ) => {
    if (option.value === "..") {
      const dirs = currentValue.split("/");

      if (currentValue.endsWith("/")) {
        dirs.pop();
      }

      dirs.pop();
      return dirs.join("/") + "/";
    }
    return option.value;
  };

  return {
    suggestions: directorySuggestions,
    onBeforeSuggestionSelected: directoryOptionHandler,
  };
};
