import { faCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import { iconContract } from "@mittwald/flow-icons/dist/contract";
import { iconContractPartner } from "@mittwald/flow-icons/dist/contractPartner";
import { iconCustomer } from "@mittwald/flow-icons/dist/customer";
import { iconExtension } from "@mittwald/flow-icons/dist/extension";
import { iconInvite } from "@mittwald/flow-icons/dist/invite";
import { iconInvoice } from "@mittwald/flow-icons/dist/invoice";
import { iconMembers } from "@mittwald/flow-icons/dist/members";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import { iconServer } from "@mittwald/flow-icons/dist/server";
import { iconSettings } from "@mittwald/flow-icons/dist/settings";
import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { lazy } from "react";
import { ModelActionBuilder } from "../../../model/actions/ModelActionBuilder";
import { Article } from "../../../model/article";
import Customer from "../../../model/customer/Customer";
import { CustomerInviteList } from "../../../model/customer/CustomerInviteList";
import { SideNavigationExtensionsForCustomer } from "../extensions/navigation/SideNavigationExtensionsForCustomer";
import CustomerSideBarHeaderLoadingView from "./components/CustomerSideBarHeaderLoadingView";

export const customerPages = PageTree.build(BacksideRenderer, {
  "/app/organizations": {
    sector: "payment",
    views: {
      main: lazy(() => import("./overview")),
    },
    linkId: "customersOverview",
    props: {
      ctaPages: [
        {
          action: {
            useValue: () => ModelActionBuilder.build(Customer, "createNew"),
          },
          title: "createCustomer",
          icon: iconCustomer,
        },
      ],
    },
    icon: iconCustomer,
  },

  "/app/organizations/:customerId": {
    views: {
      sideMenuNavigationBottom: SideNavigationExtensionsForCustomer,
      sidebarSectionHeader: lazy(
        () => import("./components/CustomerSideBarHeader"),
      ),
      sidebarSectionHeaderLoading: CustomerSideBarHeaderLoadingView,
    },
    props: {
      sideNav: true,
    },
    linkId: "customerDetails",
    icon: iconCustomer,
  },
  "/app/organizations/:customerId/projects": {
    views: {
      main: lazy(() => import("./projects/overview")),
    },
    icon: iconProject,
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Customer.useLoadByPathParam(),
                "createProject",
              ),
          },
          title: "createProject",
          icon: iconProject,
        },
      ],
    },
    linkId: "customerProjectsOverview",
    accessCheck: () =>
      ModelActionBuilder.build(Customer.useTryLoadByPathParam(), "useProjects")
        .hasAccess,
  },
  "/app/organizations/:customerId/servers": {
    sector: "basics",
    views: {
      main: lazy(() => import("./servers/overview")),
    },
    props: {
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Customer.useLoadByPathParam(),
                "createServer",
              ),
          },
          icon: iconServer,
          title: "CreateServer",
        },
      ],
      navigationSection: "management",
    },
    linkId: "customerServers",
    icon: iconServer,
    accessCheck: () =>
      ModelActionBuilder.build(Customer.useTryLoadByPathParam(), "useServers")
        .hasAccess,
  },

  "/app/organizations/:customerId/contract-partner": {
    views: {
      main: lazy(() => import("./contractPartner/contractPartner")),
    },
    props: {
      navigationSection: "invoicing",
    },
    icon: iconContractPartner,
    accessCheck: () =>
      ModelActionBuilder.build(Customer.useTryLoadByPathParam(), "hasContact")
        .hasAccess,
    linkId: "customerContractPartner",
  },
  "/app/organizations/:customerId/payment": {
    views: {
      main: lazy(() => import("./payment/payment")),
    },
    props: {
      navigationSection: "invoicing",
    },
    accessCheck: () =>
      ModelActionBuilder.build(
        Customer.useLoadByPathParam().useInvoiceSettings(),
        "update",
      ).hasAccess,
    icon: faCreditCard,
    linkId: "customerPayment",
  },

  "/app/organizations/:customerId/members": {
    props: {
      navigationSection: "settings",
      tabNav: true,
    },
    icon: iconMembers,
    linkId: "customerMembers",
  },
  "/app/organizations/:customerId/members/members": {
    views: {
      main: lazy(() => import("./members/members")),
    },
    icon: iconMembers,
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Customer.useLoadByPathParam(),
                "inviteMember",
              ),
          },
          title: "inviteCustomerMember",
          icon: iconInvite,
        },
      ],
    },
    linkId: "customerMembersOverview",
  },
  "/app/organizations/:customerId/members/invites": {
    views: {
      main: lazy(() => import("./members/invites")),
    },
    icon: iconInvite,
    props: {
      navigationSection: "management",
      ctaPages: [
        {
          action: {
            useValue: () =>
              ModelActionBuilder.build(
                Customer.useLoadByPathParam(),
                "inviteMember",
              ),
          },
          title: "inviteCustomerMember",
          icon: iconInvite,
        },
      ],
    },
    linkId: "customerInvitesOverview",
    accessCheck: () =>
      ModelActionBuilder.build(CustomerInviteList, "useLoadAllByCustomerId")
        .hasAccess,
  },
  "/app/organizations/:customerId/members/members/:membershipId": {
    views: {
      main: lazy(() => import("./members/member")),
      pageTitle: lazy(() => import("./components/MemberName")),
    },
    icon: iconMembers,
    linkId: "customerMemberDetails",
  },

  "/app/organizations/:customerId/contract-items": {
    views: {
      main: lazy(() => import("./contractItems/overview")),
    },
    props: {
      navigationSection: "invoicing",
    },
    accessCheck: () =>
      ModelActionBuilder.build(Article, "useLoadByIdDry").hasAccess,
    icon: iconContract,
    linkId: "customerArticles",
  },
  "/app/organizations/:customerId/contract-items/:contractItemId": {
    props: {
      navigationSection: "invoicing",
    },
    icon: iconContract,
    views: {
      main: lazy(() => import("./contractItems/details")),
      pageTitle: lazy(
        () => import("./contractItems/components/ContractItemTitle"),
      ),
    },
    accessCheck: () =>
      ModelActionBuilder.build(Article, "useLoadByIdDry").hasAccess,
    linkId: "customerContractItemDetails",
  },
  "/app/organizations/:customerId/invoices": {
    views: {
      main: lazy(() => import("./invoices/overview")),
    },
    props: {
      navigationSection: "invoicing",
    },
    accessCheck: () =>
      ModelActionBuilder.build(
        Customer.useLoadByPathParam().useInvoiceSettings(),
        "update",
      ).hasAccess,
    icon: iconInvoice,
    linkId: "invoices",
  },
  "/app/organizations/:customerId/invoices/:invoiceId": {
    views: {
      main: lazy(() => import("./invoices/details")),
      pageTitle: lazy(() => import("./invoices/components/InvoiceTitle")),
    },
    props: {
      navigationSection: "invoicing",
    },
    accessCheck: () =>
      ModelActionBuilder.build(
        Customer.useLoadByPathParam().useInvoiceSettings(),
        "update",
      ).hasAccess,
    icon: iconInvoice,
    linkId: "invoiceDetails",
  },

  "/app/organizations/:customerId/extensions": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceOverview/ExtensionInstanceOverviewForCustomer"
          ),
      ),
    },
    props: {
      navigationSection: "settings",
      beta: true,
    },
    icon: iconExtension,
    linkId: "customerExtensions",
  },

  "/app/organizations/:customerId/extensions/:extensionInstanceId": {
    views: {
      pageTitle: lazy(
        () => import("../extensions/components/ExtensionInstanceTitle"),
      ),
    },
    linkId: "customerExtensionInstance",
    props: {
      tabNav: true,
    },
  },
  "/app/organizations/:customerId/extensions/:extensionInstanceId/extension": {
    views: {
      main: lazy(
        () =>
          import(
            "../extensions/pages/ExtensionInstanceDetails/ExtensionInstanceDetails"
          ),
      ),
    },
  },
  "/app/organizations/:customerId/extensions/:extensionInstanceId/contributor":
    {
      views: {
        main: lazy(
          () =>
            import("../extensions/pages/ContributorDetails/ContributorDetails"),
        ),
      },
    },
  "/app/organizations/:customerId/settings": {
    views: {
      main: lazy(() => import("./settings/settings")),
    },
    icon: iconSettings,
    props: {
      navigationSection: "settings",
    },
    linkId: "customerSettings",
    accessCheck: () =>
      ModelActionBuilder.build(Customer.useTryLoadByPathParam(), "delete")
        .hasAccess,
  },
});
