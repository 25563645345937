import React, { FC } from "react";
import { MarketplaceContext } from "../../../../model/marketplace/Extension";
import ExtensionInstanceList from "../../../../model/marketplace/ExtensionInstanceList";
import { TopNavigationExtensionItem } from "./components/TopNavigationExtensionItem";

interface SideNavigationExtensionsProps {
  context: MarketplaceContext;
  identifier: string;
  fragmentPath: string;
  linkId: string;
}

export const TopNavigationExtensions: FC<SideNavigationExtensionsProps> = (
  props,
) => {
  const { context, identifier, fragmentPath, linkId } = props;

  const instances = ExtensionInstanceList.useLoadAll(
    context,
    identifier,
  ).useItems();

  return instances.map((extensionInstance) => (
    <TopNavigationExtensionItem
      extensionInstance={extensionInstance}
      fragmentPath={fragmentPath}
      key={extensionInstance.id}
      linkId={linkId}
    />
  ));
};
