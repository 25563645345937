import { Banner } from "@mittwald/flow-components/dist/components/Banner";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import Form, { useForm } from "@mittwald/flow-components/dist/components/Form";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import Section from "@mittwald/flow-components/dist/components/Section";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import React, { FC } from "react";
import { ModelActionBuilder } from "../../../../../model/actions/ModelActionBuilder";
import Domain from "../../../../../model/domain/Domain";
import { TldList } from "../../../../../model/domain/TldList";
import Conversation from "../../../../../model/support/Conversation";
import { ShowAuthCode } from "./components/ShowAuthCode";
import { Section as NewSection } from "@mittwald/flow-react-components";

export interface MoveDomainProps {
  domain: Domain;
}

export const MoveDomain: FC<MoveDomainProps> = (props) => {
  const { domain } = props;
  const visibility = useVisibilityController();
  const tld = TldList.useGetSupportedTldsForOrder().useTldFromName(
    domain.domain,
  );

  const isAuthCodeVerification = tld?.transferAuthType === "code";
  const isEmailVerification = tld?.transferAuthType === "email";
  const isPushVerification = tld?.transferAuthType === "push";

  const createConversation = ModelActionBuilder.build(
    Conversation,
    "createNew",
  );

  const form = useForm({
    onSubmit: async () => {
      visibility.hide();

      if (isPushVerification) {
        createConversation.trigger();
      }

      if (isAuthCodeVerification) {
        const authCode = await domain.createAuthCode();
        showModal("showAuthCode", <ShowAuthCode authCode={authCode} />);
      }
    },
  });
  return (
    <DefaultModal
      _size="s"
      abort={{ action: visibility.hide }}
      description="description"
      headline="moveDomain"
      headlineIcon={iconDomain}
      primary={
        !isEmailVerification
          ? {
              action: [{ form }],
              ok: isAuthCodeVerification,
              text: isAuthCodeVerification
                ? "createAuthCode"
                : isPushVerification
                  ? "createTicket"
                  : "done",
            }
          : undefined
      }
      visibility={visibility}
    >
      <Form controller={form}>
        <>
          <NewSection>
            <Section.Item>
              {tld?.transferAuthType && tld.transferAuthType !== "code" && (
                <Banner
                  description={`authType.${tld.transferAuthType}.description`}
                  headline={`authType.${tld.transferAuthType}.headline`}
                />
              )}
            </Section.Item>
          </NewSection>
          <NewSection>
            <Section.Item>
              {!isEmailVerification && (
                <CheckBox
                  name="confirmPermission"
                  rules={{ required: true }}
                  title="confirmPermission"
                />
              )}
            </Section.Item>
          </NewSection>
        </>
      </Form>
    </DefaultModal>
  );
};

export default MoveDomain;
