import { Box } from "@mittwald/flow-components/dist/components/Box";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import React, { FC } from "react";
import { ConditionsCheckbox } from "../../../../../../components/ConditionsCheckbox/ConditionsCheckbox";
import SystemSoftwareVersion, {
  FeePeriod,
} from "../../../../../../model/app/SystemSoftwareVersion";
import Customer from "../../../../../../model/customer/Customer";
import { AnyProject } from "../../../../../../model/project";
import { CustomerInfoBox } from "../../../../server/modals/OrderServerWizard/components/CustomerInfoBox";
import OrderOverview from "./OrderOverview";
import PhpInfo from "./PhpInfo";
import { Section as NewSection } from "@mittwald/flow-react-components";

interface Props {
  project: AnyProject;
  systemSoftwareVersion: SystemSoftwareVersion;
  currentFee: FeePeriod;
}

export const OverviewStepContent: FC<Props> = (props) => {
  const { project, systemSoftwareVersion, currentFee } = props;

  const customer = Customer.useLoadById(project.customerId);

  return (
    <TranslationProvider name="systemSoftwareOverviewStep" type="section">
      <NewSection>
        <Section.Item headline="checkOrder">
          <Text
            i18n={{
              id: "description",
              values: {
                email: customer.contact?.data.emailAddress,
              },
            }}
          />
        </Section.Item>
      </NewSection>
      <NewSection>
        <Section.Item>
          <ColumnLayout medium={[1, 1]}>
            <Box _flexDirection="column" _rowGap="l">
              <CustomerInfoBox customerId={customer.id} showContact={false} />
              <PhpInfo systemSoftwareVersion={systemSoftwareVersion} />
            </Box>
            <OrderOverview
              currentFee={currentFee}
              systemSoftwareVersion={systemSoftwareVersion}
            />
          </ColumnLayout>
          <ConditionsCheckbox />
        </Section.Item>
      </NewSection>
    </TranslationProvider>
  );
};

export default OverviewStepContent;
