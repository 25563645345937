import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import useOnCodeEntered from "@mittwald/flow-components/dist/hooks/useOnCodeEntered";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import React, { FC } from "react";
import { SearchBarModal } from "../../SearchBarModal/SearchBarModal";
import * as Styled from "../styled";

export const SearchButton: FC = () => {
  const translate = useTranslation();

  const handleSearchModalOpen = (): void =>
    showModal("searchBarModal", <SearchBarModal />);

  useOnCodeEntered("s", handleSearchModalOpen);

  return (
    <Styled.HeaderButton
      action={handleSearchModalOpen}
      aria-label={translate("search")}
      icon={faMagnifyingGlass}
    />
  );
};
