import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React, { useCallback, useEffect } from "react";
import { CustomerActionDeniedModal } from "../../../pages/app/customer/components/CustomerActionDeniedModal";
import TariffChangeDetailsModal from "../../../pages/app/customer/contractItems/components/TariffChangeDetailsModal";
import ContractItemTerminationModal from "../../../pages/app/customer/contractItems/termination/ContractItemTerminationModal";
import ContractItem from "../../contract/ContractItem";
import ContractItemTermination from "../../contract/ContractItemTermination";
import ContractTermination from "../../contract/ContractTermination";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultContractActionRoles = [Role.customerOwner, Role.customerMember];

const ContractItemAction: React.FC<{
  contractItem: ContractItem;
  onAction: () => void;
}> = ({ contractItem, onAction }) => {
  const customer = contractItem.contract.useCustomer();
  const isBankrupt = customer.useIsBankrupt();

  const handleAction = useCallback(() => {
    if (isBankrupt) {
      showModal("customerBankrupt", <CustomerActionDeniedModal />);
    } else {
      onAction();
    }
  }, [isBankrupt, onAction]);

  useEffect(() => {
    handleAction();
  }, [handleAction]);

  return null;
};

ModelActionBuilderRegistry.register({
  model: ContractItem,
  roles: defaultContractActionRoles,
  method: "terminate",
  isAvailable: (item) => item.isTerminationPossible,
  altAction: (action) => () =>
    showModal(
      "contractItemAction",
      <ContractItemAction
        contractItem={action.model}
        onAction={() =>
          showModal(
            "terminateServer",
            <ContractItemTerminationModal contractItem={action.model} />,
          )
        }
      />,
    ),
})
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "cancelTariffChange",
    isAvailable: (item) => item.isCancelTariffChangePossible,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTariffChange",
      },
    }),
  })
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "showTariffChangeDetails",
    isAvailable: (item) => !!item.tariffChange,
    altAction: (action) => () =>
      showModal(
        "tariffChangeDetails",
        <TariffChangeDetailsModal tariffChange={action.model.tariffChange!} />,
      ),
  })
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "cancelTermination",
    isAvailable: (item) =>
      item.terminationScheduled && !item.contract.isCancellationForbidden,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  })
  .register({
    model: ContractTermination,
    roles: defaultContractActionRoles,
    method: "cancel",
    isAvailable: (item) => !item.contract.isCancellationForbidden,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  })
  .register({
    model: ContractItemTermination,
    roles: defaultContractActionRoles,
    method: "cancel",
    isAvailable: (item) => !item.contractItem.contract.isCancellationForbidden,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  });
