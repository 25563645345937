import { faLightEmergencyOn } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import { H2 } from "@mittwald/flow-components/dist/components/H2";
import HorizontalRuler from "@mittwald/flow-components/dist/components/HorizontalRuler";
import { Popover } from "@mittwald/flow-components/dist/components/Popover";
import { UsePopoverResult } from "@mittwald/flow-components/dist/hooks/usePopover";
import { iconPhone } from "@mittwald/flow-icons/dist/phone";
import { iconSupport } from "@mittwald/flow-icons/dist/support";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  supportPhoneNumber,
  supportPhoneNumberFormatted,
} from "../../../../../../pages/app/support/config";
import { NavigationItem } from "@mittwald/flow-components/dist/components/Navigation/components";
import { SupportPin } from "../../../../../SupportPin/SupportPin";
import { isInNormalSupportTime } from "../../utils/helpers";
import { CreateSupportRequestNavigationItem } from "./components/CreateSupportRequestNavigationItem";

interface HelpdeskContextMenuProps {
  popover: UsePopoverResult;
  currentSupportPin: string | undefined;
  updateCurrentSupportPin: (pin: string) => void;
  onOpenCreateSupportRequestModal: () => void;
}

export const HelpdeskContextMenu: React.FC<HelpdeskContextMenuProps> = (
  props,
) => {
  const { popover, onOpenCreateSupportRequestModal } = props;
  const { pathname } = useLocation();
  const buildLink = useLinkBuilder();
  const isNormalSupportTime = isInNormalSupportTime();

  const phoneNumberItem = isNormalSupportTime ? (
    <NavigationItem
      action={`tel:${supportPhoneNumber}`}
      icon={iconPhone}
      title={{ text: supportPhoneNumberFormatted }}
    />
  ) : (
    <NavigationItem
      action={{
        actions: [
          {
            link: {
              path: buildLink("emergencyHotlines"),
            },
          },
          popover.visibility.hide,
        ],
      }}
      icon={faLightEmergencyOn}
      title="emergencyHotlines"
    />
  );

  useEffect(() => {
    if (popover.visibility.visible) {
      popover.visibility.hide();
    }
  }, [pathname]);

  return (
    <Popover
      {...popover}
      _alignItems="center"
      _flexDirection="column"
      _itemGap="s"
      _p="m"
      offset="m"
      placement="bottom"
    >
      <H2 i18n="helpdesk" />
      <SupportPin />
      <Box _width="98%">
        <HorizontalRuler />
      </Box>
      <Box _flexDirection="row" _itemGap="m">
        <Box _grow>
          {phoneNumberItem}
          <NavigationItem
            _internal={{
              active: false,
            }}
            action={{
              actions: [
                {
                  link: {
                    path: buildLink("support"),
                  },
                },
                popover.visibility.hide,
              ],
            }}
            icon={iconSupport}
            title="support-area"
          />
          <CreateSupportRequestNavigationItem
            onCreateSupportRequestButtonClick={onOpenCreateSupportRequestModal}
          />
        </Box>
      </Box>
    </Popover>
  );
};
