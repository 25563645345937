import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
}

const specialCharacterRegex = /[#!~%^*_+\-=?{}()<>|.,;$:/]/;

export const DatabaseUserPasswordField: FC<Props> = (props) => {
  const { name = "password" } = props;

  return (
    <TextField
      label="password"
      name={name}
      rules={{
        required: true,
        minLength: 8,
        validate: {
          includeSpecialCharacters: (v) => specialCharacterRegex.test(v),
          startsWithInvalidChar: (v) =>
            !(v.startsWith("-") || v.startsWith("_") || v.startsWith(";")),
        },
      }}
      type="passwordWithGenerator"
    />
  );
};
