import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { Heading, Section } from "@mittwald/flow-react-components";
import React, { FC } from "react";
import {
  ProSpaceArticle,
  ProSpaceArticleTag,
} from "../../../../../../model/article";
import ConfigurationArticleSection from "./ConfigurationArticleSection";
import ConfigurationDedicationSection from "./ConfigurationDedicationSection";
import ConfigurationResourceSection from "./ConfigurationResourceSection";
import ConfigurationStorageSection from "./ConfigurationStorageSection";

interface Props {
  selectedProSpaceArticles: ProSpaceArticle[];
  selectedArticle: ProSpaceArticle;
  description?: boolean;
}

export const ConfigurationSections: FC<Props> = (props) => {
  const { selectedArticle, selectedProSpaceArticles, description } = props;

  const t = useTranslation();

  const liteSelected =
    selectedArticle.articleTag === ProSpaceArticleTag.proSpaceLite;

  return (
    <SectionLayout>
      {description && (
        <Section>
          <Heading>{t("description.heading")}</Heading>
          <TextField
            label="description"
            name="description"
            rules={{ required: true }}
          />
        </Section>
      )}
      <ConfigurationArticleSection />
      <ConfigurationResourceSection
        liteSelected={liteSelected}
        selectedProSpaceArticles={selectedProSpaceArticles}
      />
      <ConfigurationStorageSection selectedArticle={selectedArticle} />
      {!liteSelected && <ConfigurationDedicationSection isDisabled={false} />}
    </SectionLayout>
  );
};

export default ConfigurationSections;
