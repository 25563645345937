import { Section } from "@mittwald/flow-react-components";
import React, { FC } from "react";
import ExtensionInstance from "../../../../../../model/marketplace/ExtensionInstance";
import { ExtensionInstanceInformationBanner } from "./ExtensionInstanceInformationBanner";

interface Props {
  extensionInstanceId: string;
  onExtensionIsExternal: (showVisibilitySection: boolean) => void;
}
export const ExtensionInstanceSelectedView: FC<Props> = (props) => {
  const { extensionInstanceId, onExtensionIsExternal } = props;
  const contributor =
    ExtensionInstance.useLoadById(extensionInstanceId).useContributor();
  const isMittwaldContributor = contributor.data.name === "mittwald";

  onExtensionIsExternal(!isMittwaldContributor);

  if (isMittwaldContributor) {
    return null;
  }

  return (
    <Section>
      <ExtensionInstanceInformationBanner
        extensionInstanceId={extensionInstanceId}
      />
    </Section>
  );
};
