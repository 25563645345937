import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconContract } from "@mittwald/flow-icons/dist/contract";

import React, { FC } from "react";
import { ModelActionBuilder } from "../../../../../model/actions/ModelActionBuilder";
import TariffChange from "../../../../../model/contract/TariffChange";
import ContractItemArticleList from "./ContractItemArticleList";
import ContractItemsPriceBox from "./ContractItemList/components/ContractItemsPriceBox";
import TariffChangeInfoText from "./TariffChangeInfoText";

interface Props {
  tariffChange: TariffChange;
}

export const TariffChangeDetailsModal: FC<Props> = (props) => {
  const { tariffChange } = props;

  const content = (
    <Section.Item headline="newArticles">
      <ContractItemArticleList articles={tariffChange.newArticles} />
      <ContractItemsPriceBox
        monthlyPrices={tariffChange.newArticles.map((a) => a.totalPrice)}
      />
    </Section.Item>
  );

  const visibility = useVisibilityController();

  return (
    <TranslationProvider name="TariffChangeDetailsModal" type="component">
      <DefaultModal
        abort={{
          text: "close",
        }}
        description={
          <Text>
            <TariffChangeInfoText tariffChange={tariffChange} />{" "}
            <Translate i18n="cancelInfos" />
          </Text>
        }
        headline="headline"
        headlineIcon={iconContract}
        primary={{
          text: "cancel",
          destructive: true,
          action: [
            ModelActionBuilder.build(
              tariffChange.contractItem,
              "cancelTariffChange",
            ),
            visibility.hide,
          ],
        }}
        visibility={visibility}
      >
        {content}
      </DefaultModal>
    </TranslationProvider>
  );
};

export default TariffChangeDetailsModal;
